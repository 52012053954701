import React, { useState } from "react";
import mentor1 from "../../assets/images/mentor1.png";
import { Carousel } from "react-responsive-carousel";
import Iconify from "../../components/iconify";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const MentorSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const navigate = useNavigate();

  const handleNav = (e) => {
    if (e === "prev") {
      setCurrentSlide(currentSlide - 1);
    } else {
      setCurrentSlide(currentSlide + 1);
    }
  };

  const updateCurrentSlide = (index) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  const sliderInfo = [
    {
      title: "Why Mentor with Us?",
      value:
        " Mentoring isn't just about guiding; it's a two-way street of growth. Here, you don't just teach; you learn, evolve, and enrich your professional journey.",
    },
    {
      title: "Mentors' Corner:",
      value:
        "Monthly articles or video sessions where mentors share their experiences, talk about industry trends, or offer advice.",
    },
    {
      title: "Engagement Beyond Mentorship:",
      value:
        " Be a part of panel discussions, industry conclaves, and webinars, becoming a voice of authority and shaping future tech directions.",
    },
  ];

  return (
    <div className="mentor-slider" id="mentors">
      <div className="container">
        <div className="info-wrap">
          <h3 className="title">For Mentors</h3>

          <Carousel
            autoPlay="off"
            selectedItem={currentSlide}
            onChange={updateCurrentSlide}
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
            showArrows={false}
          >
            {sliderInfo.map((item, index) => {
              return (
                <div className="slide-item" key={index}>
                  <h4>{item.title}</h4>
                  <p>{item.value}</p>
                </div>
              );
            })}
          </Carousel>
          <div className="slide-controls">
            <button
              onClick={() => handleNav("prev")}
              disabled={currentSlide === 0}
            >
              <Iconify icon="octicon:arrow-left-24" />
            </button>
            <button
              onClick={() => handleNav("next")}
              disabled={currentSlide === sliderInfo.length - 1}
            >
              <Iconify icon="octicon:arrow-right-24" />
            </button>
          </div>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => navigate("/signUp")}
          >
            Register as Mentor
          </Button>
        </div>
        <div className="img-wrap">
          <img src={mentor1} alt="mentor img" />
        </div>
      </div>
    </div>
  );
};

export default MentorSlider;
