// @mui

import { Typography, Stack } from "@mui/material";

// ----------------------------------------------------------------------


// ----------------------------------------------------------------------

export default function LoginSignTerms() {
  const termUrl = "http://"+window.location.host+"/termsOfService"
  const privacyURl = "http://"+window.location.host+"/privacyPolicy"

  return (
    <>
  <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                className="copyright-txt"
              >
                <Typography>
                  Copyright © 2024 Mangomentors Inc. All rights reserved
                </Typography>
                <Typography>
                    <span className="privacy"> <a  className="termPrivacy" href={privacyURl} target="_blank" rel="noopener noreferrer">
                    Privacy policy
</a></span> &nbsp; | &nbsp; <span className="privacy"> <a href={termUrl} className="termPrivacy" target="_blank" rel="noopener noreferrer">Terms of Service </a></span></Typography>
              </Stack>
    </>
  );
}


//window.location.host