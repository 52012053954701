import React, { useRef } from 'react';

import Header from "../components/header";
import Footer from "../components/footer";

export default function Landingpage() {
  const componentThreeRef = useRef(null);

  const scrollToComponentThree = () => {
    if (componentThreeRef.current) {
      componentThreeRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <>
      <Header scrollToComponentThree={scrollToComponentThree} />
      <div className="landing-content-wrap">
        <img
          className="under-const-img"
          src="/assets/images/under_const.png"
          alt="Mango"
        />
        <h3 className="const-info">We're in the midst of creating this page</h3>
        <p className="description">MANGO TREE IS GROWING ...</p>
      </div>
      <div ref={componentThreeRef}>
        <Footer />
      </div>
    </>
  );
}