// @mui

import { Typography, Stack } from "@mui/material";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function Termscondition() {
  const termUrl = "http://" + window.location.host + "/termsOfService";
  const privacyURl = "http://" + window.location.host + "/privacyPolicy";
  return (
    <>
      {/* <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                className="copyright-txt inside-copyright"
              >
                <Typography>
                  Copyright © 2024 Mangomentors Inc. All rights reserved
                </Typography>
                <Typography>
                    <span className="privacy"> <a  className="termPrivacyInner" href={privacyURl} target="_blank" rel="noopener noreferrer">
                    Privacy policy
</a></span> &nbsp; | &nbsp; <span className="privacy"> <a href={termUrl} className="termPrivacyInner" target="_blank" rel="noopener noreferrer">Terms of Service </a></span></Typography>
                       </Stack> */}

      <div className="copyrights-wrap">
        <div className="copyrights">
          <h5>
            Copyright © 2024 Mango Mentors.
            <span> All rights reserved </span>
          </h5>
        </div>
        <div className="copyrightPolicy">
          <h5>Privacy Policy | Terms of Service</h5>
        </div>
      </div>
    </>
  );
}
