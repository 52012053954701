import React from "react";

const HomeBranches = () => {
  return (
    <div className="branches-wrap">
      <div className="container">
        <h3 className="title text-center">Branches of Mentorship</h3>
        <ul>
          <li>
            <h3>Technology Development</h3>
            <p>
              Mentees that want to explore a career in tech development will be
              paired with mentors that are highly experienced in tech
              development and software engineering.
            </p>
          </li>
          <li>
            <h3>Tech Sales and Marketing</h3>
            <p>
              For mentees interested in a career in technology sales and
              marketing will be paired with experienced professionals in this
              field.
            </p>
          </li>
          <li>
            <h3>Technology Leadership</h3>
            <p>
              Mentees who want to pursue a career in management will have the
              opportunity to work with seasoned leaders and executives in
              technology management.
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default HomeBranches;
