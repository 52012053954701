import { get, postFormData } from "./BaseService";
import { apiBaseUrl } from "../config";

export const RegisterService = {
  getCountryList,
  getCityList,
  getmethodCommList,
  getCollegeAttende,
  getYearExp,
  getGraduYear,
  getAreaMajor,
  getAreaMinor,
  getIndknowledge,
  firstRegSubmit,
  secondRegSubmit,
  finalRegSubmit,
  getRoles,
};

function getCountryList() {
  const url = `get_country/`;
  return get(apiBaseUrl + url);
}
function getCityList(id) {
  const url = `get_cities/?country_id=${id}`;
  return get(apiBaseUrl + url);
}
function getmethodCommList() {
  const url = `get_method_of_communication/`;
  return get(apiBaseUrl + url);
}
function getCollegeAttende() {
  const url = `distinct_CollegeAttendingOrAttended`;
  return get(apiBaseUrl + url);
}

function getYearExp() {
  const url = `distinct_years_of_experience`;
  return get(apiBaseUrl + url);
}
function getGraduYear() {
  const url = `distinct_graduation_year`;
  return get(apiBaseUrl + url);
}
function getAreaMajor() {
  const url = `distinct_AreaOfStudyMajor`;
  return get(apiBaseUrl + url);
}
function getAreaMinor() {
  const url = `distinct_AreaOfStudyMinor`;
  return get(apiBaseUrl + url);
}
function getIndknowledge() {
  const url = `distinct_ IndustryKnowledge`;
  return get(apiBaseUrl + url);
}
function getRoles() {
  const url = `roles/`;
  return get(apiBaseUrl + url);
}
function firstRegSubmit(data) {
  const url = `first_registration_next/`;
  return postFormData(apiBaseUrl + url, data);
}
function secondRegSubmit(data) {
  const url = `second_registration_next/`;
  return postFormData(apiBaseUrl + url, data);
}
function finalRegSubmit(data) {
  const url = `third_registration_submit/`;
  return postFormData(apiBaseUrl + url, data);
}
