import React from "react";
import { Navigate } from "react-router-dom";
// import LoginPage from "../pages/LoginPage";

const ProductRoute = ({ children }) => {
  const isAuth =
    JSON.parse(localStorage.getItem("mangoMentorsAuth"))?.isAuth || undefined;
  console.log(isAuth, children, "productRoute");
  return isAuth ? children : <Navigate to="/login" />;
};

export default ProductRoute;
