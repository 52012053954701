import * as React from "react";
import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import PubSub from "pubsub-js";
import ReactLoading from "react-loading";
// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components

import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import "./style/login.css";
import "./style/loader.css";
import "./style/common.css";
import NotistackProvider from "./context/NotistackProvider";
import { StyledChart } from "./components/chart";
import ScrollToTop from "./components/scroll-to-top";
import { DataProvider } from "./hooks/DataContext";
import "./style/register.css";
import "./style/style.css";

// ----------------------------------------------------------------------

const App = () => {
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    PubSub.subscribe("msg", (msg, data) => {
      console.log(data, "loading");

      setLoading(data);
    });
  }, [isLoading]);
  return (
    <DataProvider>
      <NotistackProvider>
        <HelmetProvider>
          <BrowserRouter>
            <ThemeProvider>
              <ScrollToTop />
              <StyledChart />
              <Router />
              {isLoading ? (
                <div className="loader">
                  <ReactLoading
                    type={"spin"}
                    color={"spin"}
                    height={50}
                    width={50}
                    top={100}
                  />
                </div>
              ) : (
                <div className="loader-hide">
                  <ReactLoading />
                </div>
              )}
            </ThemeProvider>
          </BrowserRouter>
        </HelmetProvider>
      </NotistackProvider>
    </DataProvider>
  );
};

export default App;
