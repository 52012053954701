import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";

const Logo = () => {
  return (
    <Link to="/" component={RouterLink}>
      <img src="/assets/mangomentor.svg" alt="login" className="logo" />
    </Link>
  );
};

export default Logo;
