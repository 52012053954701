import React from "react";
import { Stack, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import Iconify from "../components/iconify";
import twitterIcon from "../assets/images/twitter.png";
import linkedinIcon from "../assets/images/linkedin.png";
import youtubeIcon from "../assets/images/youtube.png";
import instagramIcon from "../assets/images/instagram.png";

export default function Footer() {
  const userDataValue = JSON.parse(localStorage.getItem("mangoMentorsAuth"));

  return (
    <>
      <div className="footer" id="contactUs">
        <div className="container">
          <div className="footer-content">
            <div className="contentdiv">
              {!userDataValue?.isAuth && (
                <Link to="/">
                  <img src="/assets/images/Mangomentors-title.png" alt="logo" />
                </Link>
              )}

              {userDataValue?.isAuth && (
                <Link to="/landing">
                  <img src="/assets/images/Mangomentors-title.png" alt="logo" />
                </Link>
              )}

              <p className="element-westheimer-rd">
                Wework 57 street, <br />
                United States
              </p>
              <p className="exclusive-offers">
                Exclusive offers and first access to articles, courses & videos
              </p>
              <TextField
                label="E-mail"
                variant="outlined"
                InputProps={{
                  endAdornment: <Iconify className="icClr" icon={"ep:right"} />,
                }}
              />
              <p className="text-wrapper">Privacy Policy Content</p>
            </div>
            <div className="div2">
              <h2>COMPANY</h2>
              <Link to="" className="companydetails">
                About Us
              </Link>
              <Link to="" className="companydetails">
                Community
              </Link>
              <p className="for-any-queries">
                <span className="text-wrapper2">For any queries</span>
                <span className="mail-info">
                  CONTACT US AT :{" "}
                  <a href="mailto:hello@mangomentors.io">
                    hello@mangomentors.io
                  </a>
                </span>
              </p>
            </div>

            <div className="div3">
              <h2>TERMS</h2>
              <Link to="" className="companydetails">
                Terms & Conditions
              </Link>
              <Link to="" className="companydetails">
                Privacy Policy
              </Link>
              <Link to="" className="companydetails">
                Cookie Policy
              </Link>
            </div>

            <div className="div4">
              <Stack spacing={2} direction="row" className="socialmedia">
                <Button
                  className="linksbuttons"
                  href="https://www.instagram.com/mango.mentors/"
                  target="_blank"
                  variant="outlined"
                >
                  <img src={instagramIcon} />
                </Button>
                <Button
                  className="linksbuttons"
                  href="https://www.youtube.com/@TheMangoMentors/channels"
                  target="_blank"
                  variant="outlined"
                >
                  <img src={youtubeIcon} />
                </Button>
                <Button
                  className="linksbuttons"
                  href="https://twitter.com/Mango_Mentors"
                  target="_blank"
                  variant="outlined"
                >
                  <img src={twitterIcon} />
                </Button>
                <Button
                  className="linksbuttons"
                  href="https://www.linkedin.com/company/mango-mentors-llc/about/"
                  target="_blank"
                  variant="outlined"
                >
                  <img src={linkedinIcon} />
                </Button>
              </Stack>
            </div>
          </div>
          <p className="text-wrapper3">
            © Copyright © 2024 Mango Mentors. All rights reserved
          </p>
        </div>
      </div>
    </>
  );
}
