import { Navigate, useRoutes } from "react-router-dom";
// layouts
import SimpleLayout from "./layouts/simple";
//
import ProductRoute from "./auth/ProductRoute";
import LoginProdRoute from "./auth/LoginProdRoute";
import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";
import Home from "./pages/home";
import Page404 from "./pages/Page404";
import Landing from "./pages/LandingPage";
import Registeration from "./pages/Registeration";
import CompleteReg from "./pages/CompleteReg";
import Dashboard from "./pages/dashboard";
// ----------------------------------------------------------------------

export default function Router() {
  const IsAdmin = JSON.parse(localStorage.getItem("mangoMentorsAuth"));
  const routes = useRoutes([
    {
      path: "/login",
      element: (
        <LoginProdRoute>
          <LoginPage />
        </LoginProdRoute>
      ),
      children: [
        { element: <Navigate to="/login" /> },
        { path: "", element: <LoginPage /> },
      ],
    },
    {
      path: "/signUp",
      element: (
        <LoginProdRoute>
          <SignupPage />
        </LoginProdRoute>
      ),
      children: [
        { element: <Navigate to="/signUp" /> },
        { path: "", element: <SignupPage /> },
      ],
    },
    {
      path: "/",
      element: (
        <LoginProdRoute>
          <Home />
        </LoginProdRoute>
      ),
      children: [
        { element: <Navigate to="/" /> },
        { path: "", element: <Home /> },
      ],
      // index: true,
    },
    {
      path: "/landing",
      element: (
        <ProductRoute>
          <Landing />
        </ProductRoute>
      ),
      children: [
        { element: <Navigate to="/landing" /> },
        { path: "", element: <Landing /> },
      ],
    },
    {
      path: "/Registration",
      element: (
        <ProductRoute>
          <Registeration />
        </ProductRoute>
      ),
      children: [
        { element: <Navigate to="/Registration" />, index: true },
        { path: "", element: <Registeration /> },
      ],
    },

    {
      path: "/registerCompleted",
      element: (
        <ProductRoute>
          <CompleteReg />
        </ProductRoute>
      ),
      children: [
        { element: <Navigate to="/registerCompleted" />, index: true },
        { path: "", element: <CompleteReg /> },
      ],
    },
    IsAdmin?.role === "admin" && {
      path: "/admin",
      element: (
        <ProductRoute>
          <Dashboard />
        </ProductRoute>
      ),
      children: [
        { element: <Navigate to="/admin" />, index: true },
        { path: "", element: <Dashboard /> },
      ],
    },
    // {
    //   element: <SimpleLayout />,
    //   children: [
    //     // // { element: <Navigate to="/dashboard/campaign" />, index: true },
    //     { element: <Navigate to="/login" />, index: true },
    //     { path: "404", element: <Page404 /> },
    //     { path: "*", element: <Navigate to="/404" /> },
    //   ],
    // },
    { path: "404", element: <Page404 /> },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
