import React from "react";
import { Button } from "@mui/material";
import MenteesImg from "../../assets/images/mentees_img.png";
import { useNavigate } from "react-router-dom";

const HomeMentees = () => {
  const navigate = useNavigate();

  return (
    <div className="mentees-wrap" id="mentees">
      <h2 className="title text-center">For Mentees</h2>
      <div className="mentees-info">
        <div className="img-wrap">
          <img src={MenteesImg} alt="mentess img" />
        </div>

        <div className="info-list">
          <ul>
            <li>
              <h3>A Journey Tailored for You</h3>
              <p>
                Every mentee is unique, and so is our approach. We don't just
                offer guidance; we ensure it's relevant, resonates with your
                aspirations, and readies you for the industry.
              </p>
            </li>
            <li>
              <h3>Success Stories</h3>
              <p>
                Feature testimonials from mentees who've transitioned into
                successful roles after their Mango Mentors' experience.
              </p>
            </li>
          </ul>

          <Button
            variant="contained"
            color="secondary"
            onClick={() => navigate("/signUp")}
          >
            Register as Mentee
          </Button>
        </div>
      </div>
    </div>
  );
};

export default HomeMentees;
