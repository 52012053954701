import { get, postFormData } from "./BaseService";
import { apiBaseUrl } from "../config";

export const LoginService = { getUserList, loginPostData };

function getUserList(data) {
  const url = `https://reqres.in/api/users?page=2`;
  return get(url);
}
function loginPostData(data) {
  console.log(data, "login");
  //?email=${data?.email.toString()}&password=${data?.password.toString()}
  const url = `login/`;
  return postFormData(apiBaseUrl + url, data);
}
