import axiosWithoutAuth from "axios";
import PubSub from "pubsub-js";
import axios from "../utils/axios";

export function get(url) {
  const isAuth = JSON.parse(localStorage.getItem("mangoMentorsAuth"));

  PubSub.publish("msg", true);
  return axios
    .get(url, {
      headers: {
        Authorization: "Bearer " + isAuth?.token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export function post(url, postdata) {
  const isAuth = JSON.parse(localStorage.getItem("mangoMentorsAuth"));

  PubSub.publish("msg", true);
  const headers = {
    "Access-Control-Allow-Origin": "*",
    Authorization: "Bearer " + isAuth?.token,
  };

  return axios
    .post(url, postdata, { headers })
    .then(handleResponse)
    .catch(handleError);
}

export function postFormData(url, postdata) {
  PubSub.publish("msg", true);
  //  alert('f')
  const isAuth = JSON.parse(localStorage.getItem("mangoMentorsAuth"));

  const headers = {
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Origin": "*",
    Authorization: "Bearer " + isAuth?.token,
  };
  return axios
    .post(url, postdata, { headers })
    .then(handleResponse)
    .catch(handleError);
}

export function putFormData(url, updatedata) {
  const headers = { "Content-Type": updatedata.type };
  return axios
    .put(url, updatedata, { headers })
    .then((resp) => resp)
    .catch(handleError);
}

export function putRequest(url, postdata) {
  PubSub.publish("msg", true);
  return axios.put(url, postdata).then(handleResponse).catch(handleError);
}

export function deleteRequest(url, data) {
  const isAuth = JSON.parse(localStorage.getItem("mangoMentorsAuth"));
  PubSub.publish("msg", true);
  console.log(isAuth?.token, "de");
  let token = isAuth?.token;

  return axios
    .delete(url, { data: data, headers: { Authorization: "Bearer " + token } })
    .then((response) => response)
    .catch(handleError);
}

export function patch(url, postdata) {
  PubSub.publish("msg", true);
  return axios.patch(url, postdata).then(handleResponse).catch(handleError);
}

export function uploadImageToS3(url, data) {
  PubSub.publish("msg", true);
  const headers = { "Content-Type": data.type };
  return axiosWithoutAuth
    .put(url, data, { headers })
    .then((resp) => resp)
    .catch(handleError);
}

export function patchNoAuth(url, data) {
  PubSub.publish("msg", true);
  return axiosWithoutAuth
    .patch(url, data)
    .then((resp) => resp)
    .catch(handleError);
}

function handleError(error) {
  console.log(error, "del");
  // if(error?.detail == "Invalid token or expired token."){
  //   PubSub.publish('msg', false);
  //   history.push("/login");

  // }
  // else
  if (error.status === 403) {
    // userLogout();
    PubSub.publish("msg", false);
  } else {
    PubSub.publish("msg", false);
    // logger.log({ type: 'error', message: error.message, stack: error.stack });
  }

  throw error;
}

function handleResponse(response) {
  PubSub.publish("msg", false);
  console.log(response, "resbase");
  return response;
}
