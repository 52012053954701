import React, { useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Stack, IconButton, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Logo from "../assets/images/logo.svg";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import ListItemIcon from "@mui/material/ListItemIcon";
import Logout from "@mui/icons-material/Logout";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { UserService } from "../services/UserService";
import useData from "src/hooks/useData";
import { useSnackbar } from "notistack";
import { Icon } from "@iconify/react";
import closeFill from "@iconify/icons-eva/close-fill";

const Header = ({ scrollToComponentThree }) => {
  const isData = JSON.parse(localStorage.getItem("userstatus"));
  const navigate = useNavigate();
  const [userData, setUserdata] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { statusSetted, isstatus } = useData();
  const userDataValue = JSON.parse(localStorage.getItem("mangoMentorsAuth"));
  console.log(isData, userDataValue, "userDataValue");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const toaster = (error, type) => {
    enqueueSnackbar(error, {
      variant: type,
      action: (key) => (
        <>
          <Icon
            icon={closeFill}
            size="small"
            style={{ marginBottom: "41px", cursor: "pointer" }}
            onClick={() => closeSnackbar(key)}
          />
        </>
      ),
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (data) => {
    setAnchorEl(null);
  };

  useEffect(() => {
    statusSetted(isData == 1 ? true : false);
    console.log(isstatus, "isstatus");
    if (userDataValue?.isAuth) {
      userinfo();
    }
  }, []);

  const userinfo = async () => {
    const data = new FormData();
    setUserdata(true);
    data.append("user_id", userDataValue?.userId);
    try {
      await UserService.landingGetData(data)
        .then(
          (res) => {
            if (res) {
              if (res?.status) {
                console.log(res, "res");
                setUserdata(res.data);
              }
            }
          },
          (error) => {
            toaster(
              JSON.stringify(error?.data?.detail || "Network error"),
              "error"
            );
          }
        )
        .catch((error) => {
          toaster(JSON.stringify(error), "error");
        });
    } catch (error) {
      toaster(JSON.stringify(error), "error");
    }
  };

  const logout = () => {
    localStorage.removeItem("mangoMentorsAuth");
    setUserdata(false);
    navigate("/login", { replace: true });
  };

  const location = useLocation();
  const lastHash = useRef("");

  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1);
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        // document
        //   .getElementById(lastHash.current)
        //   ?.scrollIntoView({ behavior: "smooth", block: "start" });
        // lastHash.current = "";

        var element = document.getElementById(lastHash.current);
        var headerOffset = document.getElementById("header").offsetHeight;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition =
          elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }, 100);
    }
  }, [location]);

  return (
    <>
      <div className="header" id="header">
        <div className="logo">
          {!userDataValue?.isAuth && (
            <Link to="/">
              <img src={Logo} alt="logo" />
            </Link>
          )}

          {userDataValue?.isAuth && (
            <Link to="/landing">
              <img src={Logo} alt="logo" />
            </Link>
          )}
        </div>

        <ul
          className={
            !userDataValue?.isAuth ? "scroll-link center" : "scroll-link"
          }
        >
          {!userDataValue?.isAuth && (
            <>
              <li>
                <Link to={"/#home"}>Home</Link>
              </li>
              <li>
                <Link to={"/#mentees"}>For Mentees </Link>
              </li>
              <li>
                <Link to={"/#mentors"}>For Mentors </Link>
              </li>
              <li>
                <Link to={"/#colleges"}>For Colleges</Link>
              </li>
              <li>
                <Link to={"/#employers"}>For Employers</Link>
              </li>
              <li className="mb-link">
                <Link to={"/#contactUs"}>Contact Us</Link>
              </li>
            </>
          )}
          {userDataValue?.isAuth && (
            <li className="mb-link">
              <Link to="" onClick={scrollToComponentThree}>
                Contact Us
              </Link>
            </li>
          )}
        </ul>
        <Stack direction="row" className="header-menu">
          <Button variant="contained" className="donate-btn">
            Donate
          </Button>
          {!userDataValue?.isAuth && (
            <>
              <Button
                disabled={isstatus}
                variant="contained"
                className="apply-btn"
                onClick={() => navigate("/signUp", { replace: true })}
              >
                register
              </Button>

              <Button
                variant="contained"
                className="login-btn"
                onClick={() => navigate("/login", { replace: true })}
              >
                login
              </Button>
            </>
          )}

          {userDataValue?.isAuth && (
            <Button
              disabled={isstatus}
              variant="contained"
              className="apply-btn"
              onClick={() => navigate("/Registration", { replace: true })}
            >
              Apply now
            </Button>
          )}
        </Stack>

        {userDataValue?.isAuth === true && (
          <div className="user-dropdown">
            <Typography className="user-name">
              {userData?.first_name} {userData?.last_name}
            </Typography>
            <div className="user-option">
              <IconButton
                onClick={handleClick}
                size="small"
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <img
                  className="account-circle"
                  alt="Vector"
                  src="assets/images/AccountCircleFilled.svg"
                />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={() => handleClose("")}
                onClick={() => handleClose("")}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem onClick={() => navigate("/admin")}>
                  <ListItemIcon>
                    <DashboardIcon fontSize="small" />
                  </ListItemIcon>
                  Dashboard
                </MenuItem>
                <MenuItem onClick={logout}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
