import {
  get,
  post,
  postFormData,
  patch,
  putRequest,
  deleteRequest,
} from "./BaseService";
import { apiBaseUrl } from "../config";

export const SignupService = { signupPostData, getTitleList, getOccupantList };

function signupPostData(data) {
  const url = "signup/";
  return postFormData(apiBaseUrl + url, data);
}
function getTitleList(data) {
  const url = `get_titles/`;
  return get(apiBaseUrl + url);
}
function getOccupantList(data) {
  const url = `get_occupations/`;
  return get(apiBaseUrl + url);
}

//checking
// const ur ="https://reqres.in/api/users"
// const   d={
//       "name": "morpheus",
//       "job": "leader"
//   }
