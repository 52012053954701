import React, { createContext, useState, useEffect } from 'react'

const DataContext = createContext({
    isstatus: false,
    statusSetted: () => { },
    statusReset: () => { },
})

export const DataProvider = ({ isstatus, children }) => {
    const [status, setstatus] = useState(false)

    const statusSetted = (data) => {
        setstatus(data)
    }
    const statusReset = () => {
        setstatus(false)
    }
  
  
    return (
        <DataContext.Provider
            value={{
                isstatus: status,
                statusSetted: statusSetted,
                statusReset: statusReset
            }}
        >
            {children}
        </DataContext.Provider>
    )
}

export default DataContext
