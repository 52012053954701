import React, { useState, useEffect } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Icon } from "@iconify/react";
import closeFill from "@iconify/icons-eva/close-fill";
import { useSnackbar } from "notistack";
import { UserService } from "src/services/UserService";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
import PubSub from "pubsub-js";
import Chip from "@mui/material/Chip";

const Dashboard = () => {
  const [userInfo, setUserInfo] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const toaster = (error, type) => {
    enqueueSnackbar(error, {
      variant: type,
      action: (key) => (
        <>
          <Icon
            icon={closeFill}
            size="small"
            style={{ marginBottom: "41px", cursor: "pointer" }}
            onClick={() => closeSnackbar(key)}
          />
        </>
      ),
    });
  };

  useEffect(() => {
    getUsersEvent();
  }, []);

  const getUsersEvent = async () => {
    PubSub.publish("msg", true);
    await UserService.getUsersList()
      .then(
        (res) => {
          if (res) {
            if (res?.status) {
              setUserInfo(res.data.users_list);
              PubSub.publish("msg", false);
            }
          }
        },
        (error) => {
          PubSub.publish("msg", false);
          toaster(
            JSON.stringify(error?.data?.detail || "Network error"),
            "error"
          );
        }
      )
      .catch((error) => {
        PubSub.publish("msg", false);
        toaster(JSON.stringify(error), "error");
      });
  };

  useEffect(() => {
    console.log(userInfo, "userInfo");
  }, [userInfo]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Calculate total registered users and active users
  const totalRegisteredUsers = userInfo.length;
  const totalActiveUsers = userInfo.filter(
    (user) => user.user_status === 1
  ).length;

  const paginatedUsers = userInfo.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <>
      <Header />
      <div className="dashboard-wrap">
        <div className="container">
          <h3 className="page-title">User info</h3>

          <div className="user-info-card">
            <ul>
              <li>
                <h4>Total registered users</h4>
                <p>{totalRegisteredUsers}</p>
              </li>
              <li>
                <h4>Total active users</h4>
                <p>{totalActiveUsers}</p>
              </li>
            </ul>
          </div>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>City</TableCell>
                  <TableCell>Country</TableCell>
                  <TableCell>Created Date</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedUsers.map((user) => (
                  <TableRow key={user.user_id}>
                    <TableCell>{user.first_name}</TableCell>
                    <TableCell>{user.last_name}</TableCell>
                    <TableCell>{user.email_id}</TableCell>
                    <TableCell>{user.phone_number || "-"}</TableCell>
                    <TableCell>{user.role_name || "-"}</TableCell>
                    <TableCell>{user.city || "-"}</TableCell>
                    <TableCell>{user.country || "-"}</TableCell>
                    <TableCell>
                      {new Date(user.created_date).toLocaleString("en-US", {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      })}
                    </TableCell>
                    <TableCell>
                      {user.user_status === 1 ? (
                        <Chip label="Completed" color="success" />
                      ) : (
                        <Chip label="Incomplete" color="error" />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            rowsPerPageOptions={[5, 10, 25]}
            count={userInfo.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Dashboard;
