import React from "react";

const WorkFlow = () => {
  return (
    <div className="work-flow-wrap">
      <div className="container">
        <h3>How Does It Work?</h3>
        <p>
          Our mentorship program isn’t a one-size-fits-all approach. It’s a
          personalized path:
        </p>

        <ul className="work-flow-list">
          <li>
            <div className="icon-wrap">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="32"
                viewBox="0 0 33 32"
                fill="none"
              >
                <path
                  opacity="0.4"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.5234 19.9326L13.647 13.147L20.4326 11.0234L18.3091 17.8091L11.5234 19.9326Z"
                  stroke="#0269F1"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M15.9787 28.2933C23.056 28.2933 28.7933 22.556 28.7933 15.4787C28.7933 8.40137 23.056 2.66406 15.9787 2.66406C8.90137 2.66406 3.16406 8.40137 3.16406 15.4787C3.16406 22.556 8.90137 28.2933 15.9787 28.2933Z"
                  stroke="#0269F1"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <h5>Discovery</h5>
            <p>Understand your strengths, aspirations, and areas for growth.</p>
          </li>
          <li>
            <div className="icon-wrap">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="32"
                viewBox="0 0 33 32"
                fill="none"
              >
                <path
                  d="M16.5035 21.3307C8.82353 21.3307 7.46303 13.6767 7.22201 7.60602C7.15497 5.91737 7.12145 5.07304 7.75575 4.29184C8.39003 3.51065 9.14919 3.38256 10.6675 3.12638C12.1662 2.87352 14.1254 2.66406 16.5035 2.66406C18.8815 2.66406 20.8407 2.87352 22.3394 3.12638C23.8578 3.38256 24.6169 3.51065 25.2511 4.29184C25.8855 5.07304 25.8519 5.91737 25.7849 7.60602C25.5439 13.6767 24.1834 21.3307 16.5035 21.3307Z"
                  stroke="#0269F1"
                  stroke-width="3"
                />
                <path
                  opacity="0.5"
                  d="M25.8359 6.66406L27.1007 7.08568C28.4209 7.5257 29.0809 7.74572 29.4583 8.2695C29.8359 8.79329 29.8359 9.48904 29.8358 10.8805V10.9772C29.8358 12.1249 29.8358 12.6987 29.5595 13.1682C29.2833 13.6377 28.7817 13.9163 27.7785 14.4737L23.8359 16.6641"
                  stroke="#0269F1"
                  stroke-width="3"
                />
                <path
                  opacity="0.5"
                  d="M7.16398 6.66406L5.89916 7.08568C4.57908 7.5257 3.91904 7.74572 3.54153 8.2695C3.16401 8.79329 3.16402 9.48904 3.16406 10.8805V10.9772C3.1641 12.1249 3.16412 12.6987 3.44037 13.1682C3.71661 13.6377 4.21824 13.9163 5.22146 14.4737L9.16398 16.6641"
                  stroke="#0269F1"
                  stroke-width="3"
                />
                <path
                  opacity="0.5"
                  d="M16.5 22.6641V25.3307"
                  stroke="#0269F1"
                  stroke-width="3"
                  stroke-linecap="round"
                />
                <path
                  d="M21.1693 29.3333H11.8359L12.2882 27.0719C12.4129 26.4487 12.9601 26 13.5957 26H19.4095C20.0451 26 20.5923 26.4487 20.717 27.0719L21.1693 29.3333Z"
                  stroke="#0269F1"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  opacity="0.5"
                  d="M24.5 29.3359H8.5"
                  stroke="#0269F1"
                  stroke-width="3"
                  stroke-linecap="round"
                />
              </svg>
            </div>
            <h5>Matching</h5>
            <p>Connect you with a mentor who aligns with your goals.</p>
          </li>
          <li>
            <div className="icon-wrap">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="32"
                viewBox="0 0 33 32"
                fill="none"
              >
                <path
                  opacity="0.5"
                  d="M29.8307 9.33594L19.9911 19.1155C18.661 20.4377 17.9958 21.0986 17.1709 21.0986C16.3459 21.0985 15.6809 20.4373 14.351 19.1149L14.0319 18.7975C12.7008 17.4739 12.0352 16.8121 11.2097 16.8125C10.3841 16.8127 9.71908 17.475 8.38896 18.7995L3.16406 24.0026"
                  stroke="#0269F1"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M29.8334 16.7303V9.33594H22.3906"
                  stroke="#0269F1"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <h5>Learning & Growth</h5>
            <p>Undertake challenges, projects, and real-world scenarios.</p>
          </li>
          <li>
            <div className="icon-wrap">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="32"
                viewBox="0 0 33 32"
                fill="none"
              >
                <path
                  d="M4.5 10.6641C4.5 6.89282 4.5 5.00721 5.67157 3.83564C6.84315 2.66406 8.72876 2.66406 12.5 2.66406H20.5C24.2712 2.66406 26.1568 2.66406 27.3284 3.83564C28.5 5.00721 28.5 6.89282 28.5 10.6641V21.3307C28.5 25.1019 28.5 26.9875 27.3284 28.1591C26.1568 29.3307 24.2712 29.3307 20.5 29.3307H12.5C8.72876 29.3307 6.84315 29.3307 5.67157 28.1591C4.5 26.9875 4.5 25.1019 4.5 21.3307V10.6641Z"
                  stroke="#0269F1"
                  stroke-width="3"
                />
                <path
                  opacity="0.5"
                  d="M11.1641 3.33594V29.3359"
                  stroke="#0269F1"
                  stroke-width="3"
                  stroke-linecap="round"
                />
                <path
                  opacity="0.5"
                  d="M3.16406 16H5.83073"
                  stroke="#0269F1"
                  stroke-width="3"
                  stroke-linecap="round"
                />
                <path
                  opacity="0.5"
                  d="M3.16406 21.3359H5.83073"
                  stroke="#0269F1"
                  stroke-width="3"
                  stroke-linecap="round"
                />
                <path
                  opacity="0.5"
                  d="M3.16406 10.6641H5.83073"
                  stroke="#0269F1"
                  stroke-width="3"
                  stroke-linecap="round"
                />
                <path
                  d="M15.8359 8.66406H22.5026"
                  stroke="#0269F1"
                  stroke-width="3"
                  stroke-linecap="round"
                />
                <path
                  d="M15.8359 13.3359H22.5026"
                  stroke="#0269F1"
                  stroke-width="3"
                  stroke-linecap="round"
                />
              </svg>
            </div>
            <h5>Feedback & Refinement</h5>
            <p>Fee Regular touch points to ensure you're on track.</p>
          </li>
          <li>
            <div className="icon-wrap">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="32"
                viewBox="0 0 33 32"
                fill="none"
              >
                <path
                  opacity="0.5"
                  d="M3.16406 15.9974H29.8307M21.8307 15.9974C21.8307 17.7483 21.6927 19.4822 21.4247 21.0998C21.1567 22.7175 20.7639 24.1874 20.2686 25.4255C19.7734 26.6637 19.1854 27.6457 18.5383 28.3158C17.8913 28.9858 17.1978 29.3307 16.4974 29.3307C15.797 29.3307 15.1035 28.9858 14.4565 28.3158C13.8093 27.6457 13.2214 26.6637 12.7262 25.4255C12.2309 24.1874 11.8381 22.7175 11.57 21.0998C11.302 19.4822 11.1641 17.7483 11.1641 15.9974C11.1641 14.2465 11.302 12.5126 11.57 10.8949C11.8381 9.27727 12.2309 7.80742 12.7262 6.5693C13.2214 5.3312 13.8093 4.34906 14.4565 3.679C15.1035 3.00894 15.797 2.66406 16.4974 2.66406C17.1978 2.66406 17.8913 3.00894 18.5383 3.67901C19.1854 4.34906 19.7734 5.3312 20.2686 6.5693C20.7639 7.80742 21.1567 9.27728 21.4247 10.895C21.6927 12.5126 21.8307 14.2465 21.8307 15.9974Z"
                  stroke="#0269F1"
                  stroke-width="3"
                  stroke-linecap="round"
                />
                <path
                  d="M29.8307 15.9974C29.8307 17.7483 29.4858 19.4822 28.8158 21.0998C28.1457 22.7175 27.1637 24.1874 25.9255 25.4255C24.6874 26.6637 23.2175 27.6457 21.5998 28.3158C19.9822 28.9858 18.2483 29.3307 16.4974 29.3307C14.7465 29.3307 13.0126 28.9858 11.395 28.3158C9.77728 27.6457 8.30742 26.6637 7.0693 25.4255C5.8312 24.1874 4.84906 22.7175 4.179 21.0998C3.50894 19.4822 3.16406 17.7483 3.16406 15.9974C3.16406 14.2465 3.50894 12.5126 4.17901 10.8949C4.84906 9.27727 5.8312 7.80742 7.0693 6.5693C8.30742 5.3312 9.77728 4.34906 11.395 3.679C13.0126 3.00894 14.7465 2.66406 16.4974 2.66406C18.2483 2.66406 19.9822 3.00894 21.5998 3.67901C23.2175 4.34906 24.6874 5.3312 25.9255 6.5693C27.1637 7.80742 28.1457 9.27728 28.8158 10.895C29.4858 12.5126 29.8307 14.2465 29.8307 15.9974Z"
                  stroke="#0269F1"
                  stroke-width="3"
                />
              </svg>
            </div>
            <h5>Transitioning to the Real World</h5>
            <p>
              Prepare for interviews, workplace scenarios, and industry
              networking.
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default WorkFlow;
