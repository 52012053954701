import React from "react";
import calendar from "../../assets/images/calendar_icon.png";
import diploma from "../../assets/images/diploma_icon.png";
import about from "../../assets/images/about.png";

const AboutUs = () => {
  return (
    <div className="about-wrap employers-wrap">
      <div className="container">
        <div className="employers-info">
          <h2 className="title">About Us</h2>

          <div className="employers-list">
            <ul>
              <li>
                <div className="img-wrap">
                  <img src={diploma} alt="diploma img" />
                </div>
                <div className="info-wrap">
                  <h4>From Humble Beginnings</h4>
                  <p>
                    Delve into the story of how Mango Mentors was born out of a
                    passion for tech, a commitment to guidance, and a vision of
                    community-driven success.
                  </p>
                </div>
              </li>

              <li>
                <div className="img-wrap">
                  <img src={calendar} alt="check img" />
                </div>
                <div className="info-wrap">
                  <h4>Events Calendar</h4>
                  <p>
                    Stay updated on upcoming Mango Mentor events, webinars, and
                    workshops.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="employers-img">
          <img src={about} alt="about img" />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
