// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

 



img {
  max-width: 100%;
}





/* login style css end  */

/* new login code css start */


.welCss {
  line-height: 1.1;
  margin-top: -17px;
  font-weight: 400;
}

.logCss {
  font-weight: 500;
  margin-top: 1px;
  margin-bottom: 15px;
}

img.logoLeaf {
  margin-top: 27px;
  display: inline;
}

.spanFW {
  font-weight: 300;
}

/* new login code css end */
`, "",{"version":3,"sources":["webpack://./src/style/login.css"],"names":[],"mappings":";;AAEA;EACE;IACE,uBAAuB;EACzB;;EAEA;IACE,yBAAyB;EAC3B;AACF;;;;;;AAMA;EACE,eAAe;AACjB;;;;;;AAMA,yBAAyB;;AAEzB,6BAA6B;;;AAG7B;EACE,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA,2BAA2B","sourcesContent":["\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n\n  to {\n    transform: rotate(360deg);\n  }\n}\n\n \n\n\n\nimg {\n  max-width: 100%;\n}\n\n\n\n\n\n/* login style css end  */\n\n/* new login code css start */\n\n\n.welCss {\n  line-height: 1.1;\n  margin-top: -17px;\n  font-weight: 400;\n}\n\n.logCss {\n  font-weight: 500;\n  margin-top: 1px;\n  margin-bottom: 15px;\n}\n\nimg.logoLeaf {\n  margin-top: 27px;\n  display: inline;\n}\n\n.spanFW {\n  font-weight: 300;\n}\n\n/* new login code css end */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
