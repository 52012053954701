import React from "react";
import Header from "src/components/header";
import MentorSlider from "./mentorSlider";
import Features from "./features";
import Mentees from "./mentees";
import WorkFlow from "./workFlow";
import Branches from "./branches";
import TestimonialSlider from "./testimonialSlider";
import Colleges from "./colleges";
import Employers from "./employers";
import Promotions from "./promotions";
import Footer from "src/components/footer";
import AboutUs from "./about";
import HomeBanner from "./banner";

export default function Homepage() {
  return (
    <div className="home-wrap">
      <Header />
      <HomeBanner />
      <Features />
      <Mentees />
      <WorkFlow />
      <Branches />
      <MentorSlider />
      <Colleges />
      <Employers />
      <Promotions />
      <TestimonialSlider />
      <AboutUs />
      <Footer />
    </div>
  );
}
