import React from "react";
import { Navigate } from "react-router-dom";

// import LoginPage from "../pages/LoginPage";

const LoginProdRoute = ({ children }) => {
  const isAuth =
    localStorage.getItem("mangoMentorsAuth")?.isAuth &&
    JSON.parse(localStorage.getItem("mangoMentorsAuth"))?.isAuth;
  console.log(isAuth, children, "productRoute");
  return isAuth == true ? <Navigate to="/landing" /> : children;
};

export default LoginProdRoute;
