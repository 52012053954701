import { Helmet } from "react-helmet-async";
// @mui

import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
// @mui
import {
  Stack,
  IconButton,
  TextField,
  Link,
  Typography,
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Grid,
  FormGroup,
  FormControlLabel,
  InputAdornment,
  FormControl,
  FormLabel,
  Checkbox,
  Autocomplete,
  RadioGroup,
  Radio,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { Form, FormikProvider, useFormik, Field } from "formik";
import ReactQuill from "react-quill";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import "react-quill/dist/quill.snow.css";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Icon } from "@iconify/react";
import closeFill from "@iconify/icons-eva/close-fill";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { useDropzone } from "react-dropzone";
import register from "../assets/images/mango_register.svg";
import { commonValidation } from "src/utils/commonValidation";
import { RegisterService } from "src/services/RegisterService";
import Header from "../components/header";
import Footer from "../components/footer";
import useData from "src/hooks/useData";
import { object } from "prop-types";
// components

export default function Registeration() {
  const acceptedFileTypes = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];
  const steps = ["Personal Details", "Skill Set", "Looking For"];
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [value, setValue] = React.useState("");
  const [roleVal, setRoleVal] = React.useState("");
  const [methodCommunication, setMethodCommunication] = React.useState([]);
  const [country, setCountry] = React.useState([]);
  const [city, setCity] = React.useState([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [attende, setAttende] = useState("");
  const [CollegeAtt, setcollegeAtt] = useState([]);
  const [files, setFiles] = useState([]);
  const [files1, setFiles1] = useState([]);
  const [files2, setFiles2] = useState([]);
  const [content, setContent] = useState("");
  const [content1, setContent1] = useState("");
  const [content2, setContent2] = useState("");

  const [year, setYear] = useState("");
  const [knowledge, setKnowledge] = useState("");
  const [experience, setExperience] = useState("");
  const [areaStudy, setAreaStudy] = useState("");
  const [rdcollegeAttending, setRdcollegeAttending] = useState("");
  const [rdDegree, setRddegree] = useState("");
  const [rdProgramming, setRdprogramming] = useState("");
  const [rdCertificate, setRdcertificate] = useState("");
  const [areaStudyMinor, setAreaStudyMinor] = useState("");
  const [getYrExp, setYrExp] = useState([]);
  const [getGrdYr, setGrdYr] = useState([]);
  const [getArMajr, setArMajr] = useState([]);
  const [getArMinr, setArMinr] = useState([]);
  const [getInKwde, setInKwde] = useState([]);
  const [getRole, setRole] = useState([]);
  const { statusSetted, isstatus } = useData();

  const isAuth = JSON.parse(localStorage.getItem("mangoMentorsAuth"));

  const dropzoneStyles = {
    border: "2px dashed #cccccc",
    borderRadius: "4px",
    padding: "10px",
    textAlign: "center",
    cursor: "pointer",
  };
  const registerSchema = Yup.object().shape({
    email: Yup.string(),
    CellPhone: Yup.string(),
    roleId: Yup.string().required("Please fill out this field"),
    FirstName: Yup.string()
      .required("Please fill out this field")
      .trim("Please fill out this field"),
    LastName: Yup.string()
      .required("Please fill out this field")
      .trim("Please fill out this field"),
    methodCommunication: Yup.string().required("Please fill out this field"),
    country: Yup.string().required("Please fill out this field"),
    city: Yup.string()
      .required("Please fill out this field")
      .trim("Please fill out this field"),
    attende: Yup.string().required("Please fill out this field"),
    year: Yup.string(),
    //.required("Graduation year is required"),
    collegeAttending: Yup.string().required("Please fill out this field"),
    degree: Yup.string(),
    otherDegree: Yup.string(),
    areaStudyMinor: Yup.string(),
    areaStudy: Yup.string().required("Please fill out this field"),
    certificate: Yup.string(),
    certificateDetails: Yup.string(),
    LinkedInProfile: Yup.string()
      .required("Please fill out this field")
      .trim("Please fill out this field"),
    interest: Yup.array()
      .min(1, "Please fill out this field")
      .of(Yup.string().required("Please fill out this field"))
      .required("Please fill out this field"),
    interestDetails: Yup.string(),
    programming: Yup.string().required("Please fill out this field"),
    programmingDetails: Yup.string(),
    knowledge: Yup.string(),
    experience: Yup.string(),
    goal: Yup.string().required("Please fill out this field"),
    improve: Yup.string().required("Please fill out this field"),
    offer: Yup.string().required("Please fill out this field"),
    virtualMeet: Yup.string().required("Please fill out this field"),
    semester: Yup.string().required("Please fill out this field"),
    sharePersonal: Yup.string(),
    strenWeak: Yup.string(),
  });

  const removeFile = (index, key) => {
    console.log(files, "file");
    if (key === "cv") {
      setFiles((prevFiles) => {
        const newFiles = [...prevFiles];
        newFiles.splice(index, 1);
        return newFiles;
      });
    }
    if (key === "certificate") {
      setFiles1((prevFiles) => {
        const newFiles = [...prevFiles];
        newFiles.splice(index, 1);
        return newFiles;
      });
    }

    if (key === "video") {
      setFiles2((prevFiles) => {
        const newFiles = [...prevFiles];
        newFiles.splice(index, 1);
        return newFiles;
      });
    }
  };

  const { getRootProps: getRootProps1, getInputProps: getInputProps1 } =
    useDropzone({
      onDrop: (acceptedFiles) => {
        const isRightFormat = acceptedFileTypes.includes(acceptedFiles[0].type);
        if (isRightFormat) {
          const fileSize = 5045728;
          if (acceptedFiles && acceptedFiles[0]?.size >= fileSize) {
            return toaster(
              "File size is too large, max file size is 5 Mb",
              "error"
            );
          } else {
            setFiles(acceptedFiles);
          }
        } else {
          toaster("PDF, DOC and DOCX those file format only accepted", "error");
        }
      },
    });
  //multipe upload start
  const { getRootProps: getRootProps2, getInputProps: getInputProps2 } =
    useDropzone({
      onDrop: (acceptedFiles) => {
        const isRightFormat = acceptedFileTypes.includes(acceptedFiles[0].type);
        if (files1 && files1.length >= 5)
          return toaster("Max limit to upload is 5 files", "error");
        if (isRightFormat) {
          const fileSize = 5045728;
          let size = 0;
          files1 &&
            files1.map((item) => {
              size = size + item.size;
              if (size > fileSize) {
                return toaster(
                  "Total file size exceeds the limit, max total file size is 5 Mb",
                  "error"
                );
              }
            });
          if (size + acceptedFiles[0]?.size > fileSize) {
            return toaster(
              "Total file size exceeds the limit, max total file size is 5 Mb",
              "error"
            );
          }
          if (acceptedFiles && acceptedFiles[0]?.size >= fileSize) {
            return toaster(
              "File size is too large, max file size is 5 Mb",
              "error"
            );
          } else {
            setFiles1((prevFiles) => [...prevFiles, ...acceptedFiles]);
          }
        } else {
          toaster("PDF, DOC and DOCX those file format only accepted", "error");
        }
      },
      multiple: false,
    });
  // multiple upload end

  const { getRootProps: getRootProps3, getInputProps: getInputProps3 } =
    useDropzone({
      onDrop: (acceptedFiles) => {
        let typeVideo = ["video/mp4"];
        const isRightFormat = typeVideo.includes(acceptedFiles[0].type);
        if (isRightFormat) {
          // const fileSize = 20118904;
          const fileSize = 5045728;
          if (acceptedFiles && acceptedFiles[0]?.size >= fileSize) {
            return toaster(
              "File size is too large, max file size is 5 Mb",
              "error"
            );
          } else {
            setFiles2(acceptedFiles);
          }
        } else {
          toaster("MP4 format only accepted", "error");
        }
      },
    });

  const handleChange = (e, key) => {
    console.log("content", e);

    if (key === "c") {
      if ("<p><br></p>" === e) {
        setContent("");
      } else {
        setContent(e);
      }
    } else if (key === "c1") {
      if ("<p><br></p>" === e) {
        setContent1("");
      } else {
        setContent1(e);
      }
    } else {
      {
        if ("<p><br></p>" === e) {
          setContent2("");
        } else {
          setContent2(e);
        }
      }
    }
  };
  useEffect(() => {
    if (isstatus == true) {
      navigate("/landing", { replace: true });
    }
  }, [isstatus]);

  useEffect(() => {
    getcountryList();
    getmethodCommunicationList();
    getCollegeAttendee();
    getYearExperience();
    getGraduYear();
    getAreaMajor();
    getAreaMinor();
    getIndknowledge();
    getRoles();
  }, []);

  const getcountryList = async () => {
    await RegisterService.getCountryList()
      .then(
        (res) => {
          if (res) {
            if (res?.status) {
              setCountry(res?.data?.countries);
            }
          }
        },
        (error) => {
          toaster(
            JSON.stringify(error?.data?.detail || "Network error"),
            "error"
          );
        }
      )
      .catch((error) => {
        toaster(JSON.stringify(error), "error");
      });
  };

  const getmethodCommunicationList = async () => {
    await RegisterService.getmethodCommList()
      .then(
        (res) => {
          if (res) {
            if (res?.status) {
              setMethodCommunication(res?.data?.communication_methods);
            }
          }
        },
        (error) => {
          toaster(
            JSON.stringify(error?.data?.detail || "Network error"),
            "error"
          );
        }
      )
      .catch((error) => {
        toaster(JSON.stringify(error), "error");
      });
  };
  const getRoles = async () => {
    await RegisterService.getRoles()
      .then(
        (res) => {
          if (res) {
            if (res?.status) {
              setRole(res?.data?.roles);
            }
          }
        },
        (error) => {
          toaster(
            JSON.stringify(error?.data?.detail || "Network error"),
            "error"
          );
        }
      )
      .catch((error) => {
        toaster(JSON.stringify(error), "error");
      });
  };
  const getCollegeAttendee = async () => {
    await RegisterService.getCollegeAttende()
      .then(
        (res) => {
          if (res) {
            if (res?.status) {
              setcollegeAtt(res?.data?.CollegeAttendingOrAttended);
            }
          }
        },
        (error) => {
          toaster(
            JSON.stringify(error?.data?.detail || "Network error"),
            "error"
          );
        }
      )
      .catch((error) => {
        toaster(JSON.stringify(error), "error");
      });
  };
  const getYearExperience = async () => {
    await RegisterService.getYearExp()
      .then(
        (res) => {
          if (res) {
            if (res?.status) {
              let filteredUsers = res?.data?.years_of_experience.filter(
                (user) => user !== null
              );
              setYrExp(filteredUsers);
            }
          }
        },
        (error) => {
          toaster(
            JSON.stringify(error?.data?.detail || "Network error"),
            "error"
          );
        }
      )
      .catch((error) => {
        toaster(JSON.stringify(error), "error");
      });
  };
  const getGraduYear = async () => {
    await RegisterService.getGraduYear()
      .then(
        (res) => {
          if (res) {
            if (res?.status) {
              setGrdYr(res?.data?.graduation_year);
            }
          }
        },
        (error) => {
          toaster(
            JSON.stringify(error?.data?.detail || "Network error"),
            "error"
          );
        }
      )
      .catch((error) => {
        toaster(JSON.stringify(error), "error");
      });
  };
  const getAreaMajor = async () => {
    await RegisterService.getAreaMajor()
      .then(
        (res) => {
          if (res) {
            if (res?.status) {
              setArMajr(res?.data?.AreaOfStudyMajor);
            }
          }
        },
        (error) => {
          toaster(
            JSON.stringify(error?.data?.detail || "Network error"),
            "error"
          );
        }
      )
      .catch((error) => {
        toaster(JSON.stringify(error), "error");
      });
  };
  const getAreaMinor = async () => {
    await RegisterService.getAreaMinor()
      .then(
        (res) => {
          if (res) {
            if (res?.status) {
              let filteredUsers = res?.data?.AreaOfStudyMinor.filter(
                (user) => user !== null
              );
              setArMinr(filteredUsers);
            }
          }
        },
        (error) => {
          toaster(
            JSON.stringify(error?.data?.detail || "Network error"),
            "error"
          );
        }
      )
      .catch((error) => {
        toaster(JSON.stringify(error), "error");
      });
  };
  const getIndknowledge = async () => {
    await RegisterService.getIndknowledge()
      .then(
        (res) => {
          if (res) {
            if (res?.status) {
              let filteredUsers = res?.data?.IndustryKnowledge.filter(
                (user) => user !== null
              );
              setInKwde(filteredUsers);
            }
          }
        },
        (error) => {
          toaster(
            JSON.stringify(error?.data?.detail || "Network error"),
            "error"
          );
        }
      )
      .catch((error) => {
        toaster(JSON.stringify(error), "error");
      });
  };

  // stepper functionality start
  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  // stepper functionality end
  const toaster = (error, type) => {
    enqueueSnackbar(error, {
      variant: type,
      action: (key) => (
        <>
          <Icon
            icon={closeFill}
            size="small"
            style={{ marginBottom: "41px", cursor: "pointer" }}
            onClick={() => closeSnackbar(key)}
          />
        </>
      ),
    });
  };

  // form init start
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      FirstName: isAuth?.name || "",
      LastName: isAuth?.lname || "",
      CellPhone: isAuth?.phone || "",
      email: isAuth?.email || "",
      methodCommunication: "",
      country: "",
      city: "",
      attende: "",
      year: "",
      collegeAttending: "",
      degree: "",
      otherDegree: "",
      areaStudyMinor: "",
      areaStudy: "",
      certificate: "",
      certificateDetails: "",
      LinkedInProfile: "",
      interest: "",
      interestDetails: "",
      programming: "",
      programmingDetails: "",
      knowledge: "",
      experience: "",
      goal: "",
      offer: "",
      improve: "",
      virtualMeet: "",
      semester: "",
      sharePersonal: "",
      strenWeak: "",
      roleId: "",
    },
    validationSchema: registerSchema,
    onSubmit: async (values, { setSubmitting, setErrors, resetForm }) => {
      console.log(values);
      try {
        if (
          (content1 == "" && (values.roleId == "2" || values.roleId == "4")) ||
          (content2 == "" && (values.roleId == "3" || values.roleId == "4")) ||
          (files &&
            files?.length == 0 &&
            (values.roleId == "3" || values.roleId == "4"))
        ) {
          return toaster("Please fill the mandatory fields", "error");
          //toaster("Please fill the mandatory field", 'error')
        }
        // if ((values.interest == 4 && values.interestDetails == '') || (values.programming == 1 && values.programmingDetails == '')) {
        //   return toaster("Please fill the mandatory field", 'error');
        // }
        const data = new FormData();
        data.append("UserID", isAuth?.userId);
        //JSON.stringify(values.interest)
        data.append("InterestIDs", JSON.stringify(values.interest));
        data.append("OtherInterest", values.interestDetails);
        data.append(
          "CurrentlyInvolvedInMentorship",
          Number(values.programming)
        );
        data.append("MentorshipProgram", values.programmingDetails);
        data.append("IndustryKnowledge", values.knowledge);
        data.append("YearsOfProfessionalExperience", Number(values.experience));
        data.append("GainsMentor", content1.toString());
        data.append("GainsMentee", content2.toString());

        data.append("CareerGoalsAndObjectives", values.goal);
        data.append("SkillsOfferMentee", values.offer);
        data.append("AreaOfImprovement", values.improve);
        data.append("AvailableTimeMentee", values.virtualMeet);
        data.append("AvailableTimeMentor", values.semester);
        data.append("Challenges", values.sharePersonal);
        data.append("StrengthAndWeakness", values.strenWeak);
        data.append(
          "ShareMentorshipStory",
          content != "" ? content.toString() : ""
        );

        data.append("CV", files && files?.length > 0 ? files[0] : "");

        if (files1 && files1?.length > 0) {
          files1.forEach(function (file) {
            console.log(file, "file");
            data.append("CertificateFiles", file);
          });
        }
        data.append("VideoFile", files2 && files2?.length > 0 ? files2[0] : "");
        await RegisterService.finalRegSubmit(data)
          .then(
            (res) => {
              if (res) {
                if (res?.status) {
                  navigate("/registerCompleted", { replace: true });
                  statusSetted(true);
                  localStorage.setItem("userstatus", true);

                  toaster(res?.data?.message.toString(), "success");
                } else {
                  toaster("Registration Failed", "error");
                }
              }
            },
            (error) => {
              toaster(
                JSON.stringify(error?.data?.detail || "Network error"),
                "error"
              );
            }
          )
          .catch((error) => {
            toaster(JSON.stringify(error), "error");
          });
      } catch (error) {
        console.log(error, "er");
        setSubmitting(false);
        setErrors(error);
        toaster(JSON.stringify(error), "error");
      }
    },
  });
  const handleInputChange = (event, newInputValue, key) => {
    console.log(newInputValue, "e");
    setFieldValue(key, newInputValue);

    if (key === "attende") {
      setAttende(newInputValue);
    }
    if (key === "year") {
      setYear(newInputValue);
    }
    if (key === "areaStudy") {
      setAreaStudy(newInputValue);
    }
    if (key === "areaStudyMinor") {
      setAreaStudyMinor(newInputValue);
    }
    if (key === "knowledge") {
      setKnowledge(newInputValue);
    }
    if (key === "experience") {
      setExperience(newInputValue);
    }
  };
  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    setFieldError,
    setFieldTouched,
    values,
  } = formik;

  useEffect(() => {
    console.log(values?.interest, "int");
    if (values?.interest && values?.interest?.length) {
      let val = values?.interest.find((element) => {
        return element == 4;
      });
      if (val != 4) {
        setFieldValue("interestDetails", "");
      }
    }
  }, [values?.interest]);
  const handleNext = async () => {
    formik.validateForm().then((errors) => {
      // Check if there are any validation errors
      if (Object.keys(errors).length === 0) {
        // Proceed to the next step or perform other actions
        console.log("No validation errors, proceed to the next step");
      } else {
        console.log(Object.keys(errors), "error");
        Object.keys(errors).map((item) => {
          if (
            activeStep == 0 &&
            (item == "FirstName" ||
              item == "roleId" ||
              item == "LastName" ||
              item == "methodCommunication" ||
              item == "country" ||
              item == "city")
          ) {
            setFieldTouched(item, true);
          } else if (
            activeStep == 1 &&
            (item == "attende" ||
              item == "collegeAttending" ||
              item == "areaStudy" ||
              item == "LinkedInProfile")
          ) {
            setFieldTouched(item, true);
          }
        });
      }
    });
    if (activeStep == 0) {
      if (
        values?.FirstName &&
        values.roleId &&
        values.LastName &&
        values.methodCommunication &&
        values.country &&
        values.city.trim()
      ) {
        try {
          const data = new FormData();
          data.append("user_id", isAuth?.userId);
          data.append("firstname", values.FirstName.toString());
          data.append("lastname", values.LastName.toString());
          data.append("cellphone", values.CellPhone);
          data.append("communication_id", Number(values.methodCommunication));
          data.append("role_id", Number(values.roleId));
          data.append("city", values.city.toString());
          data.append("country_id", Number(values.country));
          await RegisterService.firstRegSubmit(data)
            .then(
              (res) => {
                if (res) {
                  if (res?.status) {
                    let newSkipped = skipped;
                    if (isStepSkipped(activeStep)) {
                      newSkipped = new Set(newSkipped.values());
                      newSkipped.delete(activeStep);
                    }
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    setSkipped(newSkipped);
                    // toaster(res?.data?.message.toString(), "success");
                  } else {
                    toaster("Registeration Failed", "error");
                  }
                }
              },
              (error) => {
                toaster(
                  JSON.stringify(error?.data?.detail || "Network error"),
                  "error"
                );
              }
            )
            .catch((error) => {
              toaster(JSON.stringify(error), "error");
            });
        } catch (error) {
          toaster(JSON.stringify(error), "error");
        }
      } else {
        toaster("Please fill the mandatory fields", "error");
      }
    } else if (activeStep == 1) {
      if (
        values?.attende &&
        values.collegeAttending &&
        values.areaStudy &&
        values.LinkedInProfile.trim()
      ) {
        if (values.collegeAttending == 1 && values.year == "") {
          return toaster("Please fill the mandatory fields", "error");
        }
        const d = new Date();
        let year = d.getFullYear();
        console.log(year, "year");
        if (values.collegeAttending == 1 && year > values?.year) {
          return toaster(
            "Graduation year should be current year or future year",
            "error"
          );
        }
        try {
          const data = new FormData();
          data.append("UserID", isAuth?.userId);
          data.append("CollegeAttendingOrAttended", values.attende.toString());
          data.append(
            "CurrentlyAttendingCollege",
            Number(values.collegeAttending)
          );
          data.append(
            "GraduationYear",
            values.year != "" ? Number(values.year) : 0
          );
          data.append(
            "HighestDegreeID",
            values.degree == 0 ? "" : Number(values.degree)
          );
          data.append("OtherDegreeSpecified", values.otherDegree);
          data.append("AreaOfStudyMajor", values.areaStudy.toString());
          data.append("AreaOfStudyMinor", values.areaStudyMinor);
          data.append("HoldCertifications", Number(values.certificate));
          data.append("CertificationsList", values.certificateDetails);
          data.append("LinkedInProfileLink", values.LinkedInProfile.toString());

          await RegisterService.secondRegSubmit(data)
            .then(
              (res) => {
                if (res) {
                  if (res?.status) {
                    let newSkipped = skipped;
                    if (isStepSkipped(activeStep)) {
                      newSkipped = new Set(newSkipped.values());
                      newSkipped.delete(activeStep);
                    }
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    setSkipped(newSkipped);
                    // toaster(res?.data?.message.toString(), "success");
                  } else {
                    toaster("Registration Failed", "error");
                  }
                }
              },
              (error) => {
                toaster(
                  JSON.stringify(error?.data?.detail || "Network error"),
                  "error"
                );
              }
            )
            .catch((error) => {
              toaster(JSON.stringify(error), "error");
            });
        } catch (error) {
          toaster(JSON.stringify(error), "error");
        }
      } else {
        toaster("Please fill the mandatory fields", "error");
      }
    }
  };
  // const getcityList = async (id) => {
  //   await RegisterService.getCityList(id)
  //     .then(
  //       (res) => {
  //         if (res) {
  //           if (res?.status) {
  //             console.log(res?.data.cities, "ch");
  //             // setCity(res?.data?.cities)
  //             setCity([
  //               { id: 9885, name: "Evendale" },
  //               { id: 985, name: "Evendeeale" },
  //             ]);
  //           }
  //         }
  //       },
  //       (error) => {
  //         toaster(JSON.stringify(error?.data?.detail || "Network error"), "error");
  //       }
  //     )
  //     .catch((error) => {
  //       toaster(JSON.stringify(error), "error");
  //     });
  // };
  const commonHandle = (data, key) => {
    console.log(data.target.value, key, "cc");
    setFieldValue(key, data.target.value);

    if (key == "country") {
      setValue(data.target.value);
    } else if (key == "collegeAttending") {
      setRdcollegeAttending(data.target.value);
      if (data.target.value == 0) setYear("");
    } else if (key == "degree") {
      setRddegree(data.target.value);
      if (data.target.value != 5) setFieldValue("otherDegree", "");
    } else if (key == "certificate") {
      setRdcertificate(data.target.value);
    } else if (key == "programming") {
      setRdprogramming(data.target.value);
    }
  };
  const roleChange = (data, key) => {
    // console.log(typeof (data.target.value.toString()), "key")
    setFieldTouched("roleId", false);
    if (data?.target?.value != "") {
      setRoleVal(data.target.value.toString());
      setFieldValue(key, data.target.value.toString());
    }
    if (data.target.value == "3") {
      //mentee
      setFieldValue("virtualMeet", "  ");
      setFieldValue("offer", "  ");
      //req
      setFieldValue("semester", "");
      setFieldValue("improve", "");
    } else if (data.target.value == "2") {
      //mentor
      //req
      setFieldValue("virtualMeet", "");
      setFieldValue("offer", "");

      setFieldValue("semester", "  ");
      setFieldValue("improve", "  ");
    } else {
      //both
      setFieldValue("virtualMeet", "");
      setFieldValue("offer", "");
      setFieldValue("semester", "");
      setFieldValue("improve", "");
    }
  };
  const handleCancel = () => {
    navigate("/landing", { replace: true });
  };
  const handleKeyDown = (event, key) => {
    if (key == "c") {
      if (content.length >= 508 && event.key !== "Backspace") {
        event.preventDefault();
      }
    } else if (key == "c1") {
      if (content1.length >= 263 && event.key !== "Backspace") {
        event.preventDefault();
      }
    } else if (key == "c2") {
      if (content2.length >= 263 && event.key !== "Backspace") {
        event.preventDefault();
      }
    }
  };
  // form init end
  return (
    <>
      <div className="maindiv">
        <Header />
        <div className="container">
          <Stack spacing={2} alignItems="center">
            <Grid container spacing={1}>
              <div className="reg-wrap">
                <Grid item xs={12} md={4} lg={3}>
                  <div className="imgreg-wrap">
                    <img src={register} alt="logo" className="logoReg" />
                  </div>
                </Grid>
                <Grid item xs={12} md={8} lg={9}>
                  <div className="form-wrap">
                    <h1 className="headerMentor">
                      Mango Mentors Application Form
                    </h1>
                    <span className="spanMentor">
                      We're interested in learning more about you, so please
                      share the requested information with us.
                    </span>
                    <br></br>
                    <br></br>
                    <br></br>

                    {/* stepper start */}
                    <Box sx={{ width: "100%" }}>
                      <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                          const stepProps = {};
                          const labelProps = {};
                          // if (isStepOptional(index)) {
                          //   labelProps.optional = (
                          //     <Typography variant="caption">Optional</Typography>
                          //   );
                          // }
                          if (isStepSkipped(index)) {
                            stepProps.completed = false;
                          }
                          return (
                            <Step
                              key={label}
                              {...stepProps}
                              className="stepForm"
                            >
                              <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                          );
                        })}
                      </Stepper>
                      <br></br>

                      {activeStep === steps.length ? (
                        <React.Fragment>
                          <Typography sx={{ mt: 2, mb: 1 }}>
                            All steps completed - you&apos;re finished
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              pt: 2,
                            }}
                          >
                            <Box sx={{ flex: "1 1 auto" }} />
                            <Button onClick={handleReset}>Reset</Button>
                          </Box>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <FormikProvider value={formik}>
                            <Form
                              noValidate
                              autoComplete="off"
                              onSubmit={handleSubmit}
                            >
                              {/* persnal details start */}
                              {activeStep + 1 == 1 && (
                                <Stack spacing={2} alignItems="center">
                                  <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                      <TextField
                                        onChange={(e) =>
                                          roleChange(e, "roleId")
                                        }
                                        fullWidth
                                        select
                                        required
                                        className="inpClr selc labSel"
                                        label="Select Role"
                                        name="roleId"
                                        value={roleVal}
                                        error={Boolean(
                                          touched.roleId && errors.roleId
                                        )}
                                        helperText={
                                          touched.roleId && errors.roleId
                                        }
                                      >
                                        {getRole.map((option) => (
                                          <MenuItem
                                            key={option?.id}
                                            value={option?.id}
                                          >
                                            {option?.role}
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <TextField
                                        InputProps={{
                                          autoComplete: "off",
                                        }}
                                        inputProps={{
                                          maxLength: 40,
                                        }}
                                        required
                                        className="inpClr"
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        label="First Name"
                                        {...getFieldProps("FirstName")}
                                        error={Boolean(
                                          touched.FirstName && errors.FirstName
                                        )}
                                        helperText={
                                          touched.FirstName && errors.FirstName
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <TextField
                                        InputProps={{
                                          autoComplete: "off",
                                        }}
                                        inputProps={{
                                          maxLength: 40,
                                        }}
                                        required
                                        className="inpClr"
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        label="Last Name"
                                        {...getFieldProps("LastName")}
                                        error={Boolean(
                                          touched.LastName && errors.LastName
                                        )}
                                        helperText={
                                          touched.LastName && errors.LastName
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <TextField
                                        InputProps={{
                                          autoComplete: "off",
                                        }}
                                        disabled
                                        className="inpClr"
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        label="Email Address"
                                        {...getFieldProps("email")}
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <TextField
                                        // type="number"
                                        onKeyPress={
                                          commonValidation.validateNumber
                                        }
                                        InputProps={{
                                          autoComplete: "off",
                                        }}
                                        className="inpClr"
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        label="Cell Phone"
                                        {...getFieldProps("CellPhone")}
                                        // error={Boolean(touched.CellPhone && errors.CellPhone)}
                                        // helperText={touched.CellPhone && errors.CellPhone}
                                      />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                      <TextField
                                        fullWidth
                                        select
                                        required
                                        className="inpClr selc labSel"
                                        label="Preferred method of communication"
                                        {...getFieldProps(
                                          "methodCommunication"
                                        )}
                                        error={Boolean(
                                          touched.methodCommunication &&
                                            errors.methodCommunication
                                        )}
                                        helperText={
                                          touched.methodCommunication &&
                                          errors.methodCommunication
                                        }
                                      >
                                        {methodCommunication.map((option) => (
                                          <MenuItem
                                            key={option.id}
                                            value={option.id}
                                          >
                                            {option.method}
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <TextField
                                        fullWidth
                                        select
                                        required
                                        className="inpClr selc countryDrp labSel"
                                        label="Country"
                                        name="country"
                                        value={value}
                                        error={Boolean(
                                          touched.country && errors.country
                                        )}
                                        helperText={
                                          touched.country && errors.country
                                        }
                                        onChange={(e) =>
                                          commonHandle(e, "country")
                                        }
                                      >
                                        {country.map((option) => (
                                          <MenuItem
                                            key={option.id}
                                            value={option.id}
                                          >
                                            {option.country}
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <TextField
                                        InputProps={{
                                          autoComplete: "off",
                                        }}
                                        inputProps={{
                                          maxLength: 40,
                                        }}
                                        required
                                        className="inpClr"
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        label="City"
                                        onKeyPress={
                                          commonValidation.validateHtml
                                        }
                                        {...getFieldProps("city")}
                                        error={Boolean(
                                          touched.city && errors.city
                                        )}
                                        helperText={touched.city && errors.city}
                                      />
                                      {/* <TextField
                                  fullWidth
                                  select
                                  required
                                  className="inpClr"
                                  label="City"
                                  {...getFieldProps("city")}
                                  error={Boolean(touched.city && errors.city)}
                                  helperText={touched.city && errors.city}

                                >
                                  {city.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                      {option.name}
                                    </MenuItem>
                                  ))}
                                </TextField> */}
                                    </Grid>
                                  </Grid>
                                </Stack>
                              )}
                              {/* persnal details end */}
                              {/* skill set start */}
                              {activeStep + 1 == 2 && (
                                <Stack spacing={2} alignItems="center">
                                  <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                      <Autocomplete
                                        id="free-solo-demo"
                                        freeSolo
                                        value={attende}
                                        disableClearable
                                        onInputChange={(event, newInputValue) =>
                                          handleInputChange(
                                            event,
                                            newInputValue,
                                            "attende"
                                          )
                                        }
                                        className="inpClr autoComplete"
                                        options={CollegeAtt.map(
                                          (option) => option
                                        )}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            inputProps={{
                                              ...params.inputProps,
                                              maxLength: 255, // Set the maximum length for the input
                                            }}
                                            className="inpClr"
                                            InputProps={{
                                              ...params.InputProps,
                                              type: "search",
                                            }}
                                            {...getFieldProps("attende")}
                                            required
                                            error={Boolean(
                                              touched.attende && errors.attende
                                            )}
                                            helperText={
                                              touched.attende && errors.attende
                                            }
                                            label="College Attending / Attended"
                                          />
                                        )}
                                      />
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                      {" "}
                                      <Typography className="typo_sub">
                                        Are you currently attending college?{" "}
                                        <span className="astk">*</span>
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} lg={3}>
                                      <FormControl>
                                        <RadioGroup
                                          row
                                          aria-labelledby="demo-form-control-label-placement"
                                          className="inpClr"
                                          onChange={(e) =>
                                            commonHandle(e, "collegeAttending")
                                          }
                                          value={rdcollegeAttending}
                                          name="collegeAttending"
                                          //  {...getFieldProps("collegeAttending")}
                                        >
                                          <FormControlLabel
                                            className="inpClr"
                                            value="1"
                                            control={<Radio />}
                                            label="Yes"
                                          />
                                          <FormControlLabel
                                            className="inpClr"
                                            value="0"
                                            control={<Radio />}
                                            label="No"
                                          />
                                        </RadioGroup>
                                        <p className="errPara">
                                          {touched.collegeAttending &&
                                            errors.collegeAttending}
                                        </p>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12} lg={5}>
                                      <Autocomplete
                                        id="free-solo-demo"
                                        disabled={
                                          values.collegeAttending == 0 ||
                                          values.collegeAttending == ""
                                        }
                                        freeSolo
                                        value={year}
                                        disableClearable
                                        onInputChange={(event, newInputValue) =>
                                          handleInputChange(
                                            event,
                                            newInputValue,
                                            "year"
                                          )
                                        }
                                        className="inpClr autoComplete"
                                        options={getGrdYr.map(
                                          (option) => option
                                        )}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            className="inpClr"
                                            InputProps={{
                                              ...params.InputProps,
                                              type: "search",
                                            }}
                                            onKeyPress={
                                              commonValidation.validateNumberYear
                                            }
                                            disabled={
                                              values.collegeAttending == 0 ||
                                              values.collegeAttending == ""
                                            }
                                            {...getFieldProps("year")}
                                            required
                                            error={Boolean(
                                              year == "" &&
                                                rdcollegeAttending == "1"
                                                ? true
                                                : false
                                            )}
                                            helperText={
                                              year == "" &&
                                              rdcollegeAttending == "1"
                                                ? "Please fill out this field"
                                                : ""
                                            }
                                            label="If Yes, what is your graduation year ('N/A' if no)"
                                          />
                                        )}
                                      />
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                      {" "}
                                      <Typography className="typo_sub">
                                        Highest Level of Degree (Obtained or in
                                        process)
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} lg={8}>
                                      <FormControl>
                                        <RadioGroup
                                          row
                                          aria-labelledby="demo-form-control-label-placement"
                                          className="inpClr"
                                          onChange={(e) =>
                                            commonHandle(e, "degree")
                                          }
                                          value={rdDegree}
                                          name="degree"
                                          // {...getFieldProps("degree")}
                                        >
                                          <FormControlLabel
                                            className="inpClr"
                                            value="1"
                                            control={<Radio />}
                                            label="Bachelors"
                                          />
                                          <FormControlLabel
                                            className="inpClr"
                                            value="2"
                                            control={<Radio />}
                                            label="Associates"
                                          />
                                          <FormControlLabel
                                            className="inpClr"
                                            value="3"
                                            control={<Radio />}
                                            label="Masters"
                                          />
                                          <FormControlLabel
                                            className="inpClr"
                                            value="4"
                                            control={<Radio />}
                                            label="PhD"
                                          />
                                          <FormControlLabel
                                            className="inpClr"
                                            value="5"
                                            control={<Radio />}
                                            label="Other"
                                          />
                                        </RadioGroup>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <TextField
                                        InputProps={{
                                          autoComplete: "off",
                                        }}
                                        inputProps={{
                                          maxLength: 255,
                                        }}
                                        onKeyPress={
                                          commonValidation.validateHtmlElement
                                        }
                                        disabled={
                                          values.degree != 5 ||
                                          values.degree == ""
                                        }
                                        className="inpClr"
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        label="If 'Other', please specify"
                                        {...getFieldProps("otherDegree")}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Autocomplete
                                        disableClearable
                                        id="free-solo-demo"
                                        freeSolo
                                        value={areaStudy}
                                        onInputChange={(event, newInputValue) =>
                                          handleInputChange(
                                            event,
                                            newInputValue,
                                            "areaStudy"
                                          )
                                        }
                                        className="inpClr autoComplete"
                                        options={getArMajr.map(
                                          (option) => option
                                        )}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            inputProps={{
                                              ...params.inputProps,
                                              maxLength: 255,
                                            }}
                                            className="inpClr"
                                            InputProps={{
                                              ...params.InputProps,
                                              type: "search",
                                            }}
                                            {...getFieldProps("areaStudy")}
                                            required
                                            error={Boolean(
                                              touched.areaStudy &&
                                                errors.areaStudy
                                            )}
                                            helperText={
                                              touched.areaStudy &&
                                              errors.areaStudy
                                            }
                                            label="Area of Study (Major)"
                                          />
                                        )}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Autocomplete
                                        disableClearable
                                        id="free-solo-demo"
                                        freeSolo
                                        value={areaStudyMinor}
                                        onInputChange={(event, newInputValue) =>
                                          handleInputChange(
                                            event,
                                            newInputValue,
                                            "areaStudyMinor"
                                          )
                                        }
                                        className="inpClr autoComplete"
                                        options={getArMinr.map(
                                          (option) => option
                                        )}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            inputProps={{
                                              ...params.inputProps,
                                              maxLength: 255,
                                            }}
                                            className="inpClr"
                                            InputProps={{
                                              ...params.InputProps,
                                              type: "search",
                                            }}
                                            {...getFieldProps("areaStudyMinor")}
                                            label="Additional Area of study (Minor)"
                                          />
                                        )}
                                      />
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                      {" "}
                                      <Typography className="typo_sub">
                                        Do you hold any certifications?
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} lg={3}>
                                      <FormControl>
                                        <RadioGroup
                                          row
                                          aria-labelledby="demo-form-control-label-placement"
                                          className="inpClr"
                                          onChange={(e) =>
                                            commonHandle(e, "certificate")
                                          }
                                          value={rdCertificate}
                                          name="certificate"
                                          // {...getFieldProps("certificate")}
                                        >
                                          <FormControlLabel
                                            className="inpClr"
                                            value="1"
                                            control={<Radio />}
                                            label="Yes"
                                          />
                                          <FormControlLabel
                                            className="inpClr"
                                            value="0"
                                            control={<Radio />}
                                            label="No"
                                          />
                                        </RadioGroup>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <TextField
                                        InputProps={{
                                          autoComplete: "off",
                                        }}
                                        inputProps={{
                                          maxLength: 255,
                                        }}
                                        onKeyPress={
                                          commonValidation.validateHtmlElement
                                        }
                                        disabled={
                                          values.certificate == 0 ||
                                          values.certificate == ""
                                        }
                                        className="inpClr"
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        label="If 'Yes', please specify"
                                        {...getFieldProps("certificateDetails")}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <TextField
                                        InputProps={{
                                          autoComplete: "off",
                                        }}
                                        inputProps={{
                                          maxLength: 255,
                                        }}
                                        onKeyPress={
                                          commonValidation.validateLinkdin
                                        }
                                        className="inpClr"
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        required
                                        label="LinkedIn Profile Link"
                                        {...getFieldProps("LinkedInProfile")}
                                        error={Boolean(
                                          touched.LinkedInProfile &&
                                            errors.LinkedInProfile
                                        )}
                                        helperText={
                                          touched.LinkedInProfile &&
                                          errors.LinkedInProfile
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                </Stack>
                              )}
                              {/* skill set end */}
                              {/* looking for start */}
                              {activeStep + 1 == 3 && (
                                <Stack spacing={2} alignItems="center">
                                  <Grid container spacing={1}>
                                    <Grid item xs={12} lg={6}>
                                      {" "}
                                      <Typography className="typo_sub ext">
                                        Which area of Mango Mentors are you MOST
                                        interested in?{" "}
                                        <span className="astk">*</span>{" "}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      lg={6}
                                      className="checkGrid"
                                    >
                                      <FormControl
                                        component="fieldset"
                                        className="inpClr"
                                      >
                                        <FormGroup aria-label="position" row>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="interest"
                                              value="1"
                                            />
                                            &nbsp; Tech Development
                                          </label>{" "}
                                          &nbsp; &nbsp; &nbsp;
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="interest"
                                              value="2"
                                            />
                                            &nbsp; Technical Leadership
                                          </label>
                                          &nbsp; &nbsp; &nbsp;
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="interest"
                                              value="3"
                                            />
                                            &nbsp; Technical Sales/Marketing
                                          </label>
                                          &nbsp; &nbsp; &nbsp;
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="interest"
                                              value="4"
                                            />
                                            &nbsp; Others
                                          </label>
                                          <p className="errPara">
                                            {touched.interest &&
                                              errors.interest}
                                          </p>
                                        </FormGroup>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <TextField
                                        InputProps={{
                                          autoComplete: "off",
                                        }}
                                        inputProps={{
                                          maxLength: 255,
                                        }}
                                        onKeyPress={
                                          commonValidation.validateHtmlElement
                                        }
                                        disabled={
                                          (values?.interest &&
                                            values?.interest.find((element) => {
                                              return element == 4;
                                            }) != 4) ||
                                          values?.interest == ""
                                        }
                                        className="inpClr"
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        label="If 'Other', please specify"
                                        {...getFieldProps("interestDetails")}
                                      />
                                    </Grid>
                                    <Grid item xs={12} lg={5}>
                                      {" "}
                                      <Typography className="typo_sub prg">
                                        Are you currently involved in a
                                        mentorship program?{" "}
                                        <span className="astk">*</span>
                                      </Typography>
                                    </Grid>
                                    <Grid item lg={3} className="radiobtn">
                                      <FormControl>
                                        <RadioGroup
                                          row
                                          aria-labelledby="demo-form-control-label-placement"
                                          className="inpClr"
                                          onChange={(e) =>
                                            commonHandle(e, "programming")
                                          }
                                          value={rdProgramming}
                                          name="programming"
                                          // {...getFieldProps("programming")}
                                          // error={Boolean(
                                          //   touched.programming &&
                                          //   errors.programming
                                          // )}
                                          // helperText={
                                          //   touched.programming &&
                                          //   errors.programming
                                          // }
                                        >
                                          <FormControlLabel
                                            className="inpClr"
                                            value="1"
                                            control={<Radio />}
                                            label="Yes"
                                          />
                                          <FormControlLabel
                                            className="inpClr"
                                            value="0"
                                            control={<Radio />}
                                            label="No"
                                          />
                                        </RadioGroup>
                                      </FormControl>
                                      <p className="errPara">
                                        {touched.programming &&
                                          errors.programming}
                                      </p>
                                    </Grid>
                                    <Grid item xs={4}></Grid>
                                    <Grid item xs={12} className="brk"></Grid>
                                    <Grid item xs={12}>
                                      <TextField
                                        InputProps={{
                                          autoComplete: "off",
                                        }}
                                        inputProps={{
                                          maxLength: 255,
                                        }}
                                        disabled={
                                          values.programming == 0 ||
                                          values.programming == ""
                                        }
                                        onKeyPress={
                                          commonValidation.validateHtmlElement
                                        }
                                        className="inpClr"
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        label="If 'Yes', please specify"
                                        {...getFieldProps("programmingDetails")}
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                      <Autocomplete
                                        disableClearable
                                        id="free-solo-demo"
                                        freeSolo
                                        value={knowledge}
                                        onInputChange={(event, newInputValue) =>
                                          handleInputChange(
                                            event,
                                            newInputValue,
                                            "knowledge"
                                          )
                                        }
                                        className="inpClr autoComplete"
                                        options={getInKwde.map(
                                          (option) => option
                                        )}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            inputProps={{
                                              ...params.inputProps,
                                              maxLength: 255,
                                            }}
                                            className="inpClr"
                                            InputProps={{
                                              ...params.InputProps,
                                              type: "search",
                                            }}
                                            onKeyPress={
                                              commonValidation.validateHtmlElement
                                            }
                                            {...getFieldProps("knowledge")}
                                            label="What industry do you have the most knowledge of? ('N/A' if not applicable)"
                                          />
                                        )}
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                      <Autocomplete
                                        disableClearable
                                        id="free-solo-demo"
                                        freeSolo
                                        value={experience}
                                        onInputChange={(event, newInputValue) =>
                                          handleInputChange(
                                            event,
                                            newInputValue,
                                            "experience"
                                          )
                                        }
                                        className="inpClr autoComplete"
                                        options={getYrExp.map(
                                          (option) => option
                                        )}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            className="inpClr"
                                            InputProps={{
                                              ...params.InputProps,
                                              type: "search",
                                            }}
                                            inputProps={{
                                              ...params.inputProps,
                                              maxLength: 3,
                                            }}
                                            onKeyPress={
                                              commonValidation.validateNumberYear
                                            }
                                            {...getFieldProps("experience")}
                                            label="Years of professional experience"
                                          />
                                        )}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <div>
                                        <div
                                          {...getRootProps1()}
                                          style={dropzoneStyles}
                                          className="dropContent"
                                        >
                                          <input {...getInputProps1()} />
                                          <UploadFileIcon />
                                          <p>Click to Submit Resume/CV </p>
                                        </div>
                                        <div className="fiUpload">
                                          <ul>
                                            {files.map((file, index) => (
                                              <li key={index}>
                                                {file.name}
                                                &nbsp;&nbsp;{" "}
                                                <DeleteOutlineIcon
                                                  className="del"
                                                  onClick={() =>
                                                    removeFile(index, "cv")
                                                  }
                                                />
                                                {/* <button onClick={() => removeFile(index, 'cv')}>Remove</button> */}
                                              </li>
                                            ))}
                                          </ul>
                                        </div>
                                        {files &&
                                        files?.length == 0 &&
                                        (values.roleId == "3" ||
                                          values.roleId == "4") ? (
                                          <p className="errPara">
                                            Please fill out this field
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </Grid>

                                    <Grid item xs={12}>
                                      <div className="layoutBorder">
                                        <label>
                                          Other relevant certifications/work
                                          that you would like to share?{" "}
                                        </label>{" "}
                                        <div
                                          {...getRootProps2()}
                                          style={dropzoneStyles}
                                          className="dropContent"
                                        >
                                          <input
                                            {...getInputProps2()}
                                            multiple={false}
                                          />
                                          <UploadFileIcon />

                                          <p>Click to Upload Certification </p>
                                        </div>
                                        <div className="fiUpload">
                                          <ul>
                                            {files1.map((file, index) => (
                                              <li key={index}>
                                                {file.name}
                                                &nbsp;&nbsp;{" "}
                                                <DeleteOutlineIcon
                                                  className="del"
                                                  onClick={() =>
                                                    removeFile(
                                                      index,
                                                      "certificate"
                                                    )
                                                  }
                                                />
                                                {/* <button onClick={() => removeFile(index, 'certificate')}>Remove</button> */}
                                              </li>
                                            ))}
                                          </ul>
                                        </div>
                                      </div>
                                    </Grid>
                                    {values.roleId != "3" && (
                                      <Grid item xs={12}>
                                        <div className="layoutBorder">
                                          <label>
                                            Why do you want to become a mentor
                                            and what do you hope to gain from
                                            being one?{" "}
                                            <span className="astk">*</span>{" "}
                                          </label>
                                          <ReactQuill
                                            value={content1}
                                            onChange={(e) =>
                                              handleChange(e, "c1")
                                            }
                                            onKeyDown={(e) =>
                                              handleKeyDown(e, "c1")
                                            }
                                          />
                                        </div>
                                        {content1 == "" &&
                                        (values.roleId == "2" ||
                                          values.roleId == "4") ? (
                                          <p className="errPara">
                                            Please fill out this field
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                      </Grid>
                                    )}
                                    {values.roleId != "2" && (
                                      <Grid item xs={12}>
                                        <div className="layoutBorder">
                                          <label>
                                            Why do you want to become a mentee
                                            and what do you hope to gain from
                                            being one?{" "}
                                            <span className="astk">*</span>{" "}
                                          </label>
                                          <ReactQuill
                                            value={content2}
                                            onChange={(e) =>
                                              handleChange(e, "c2")
                                            }
                                            onKeyDown={(e) =>
                                              handleKeyDown(e, "c2")
                                            }
                                          />
                                        </div>
                                        {content2 == "" &&
                                        (values.roleId == "3" ||
                                          values.roleId == "4") ? (
                                          <p className="errPara">
                                            Please fill out this field
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                      </Grid>
                                    )}
                                    <Grid item xs={12}>
                                      <TextField
                                        InputProps={{
                                          autoComplete: "off",
                                        }}
                                        inputProps={{
                                          maxLength: 255,
                                        }}
                                        onKeyPress={
                                          commonValidation.validateHtmlElement
                                        }
                                        className="inpClr"
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        required
                                        label="What are your current career goals or objectives?"
                                        {...getFieldProps("goal")}
                                        error={Boolean(
                                          touched.goal && errors.goal
                                        )}
                                        helperText={touched.goal && errors.goal}
                                      />
                                    </Grid>
                                    {values.roleId != "3" && (
                                      <Grid item xs={12}>
                                        <TextField
                                          InputProps={{
                                            autoComplete: "off",
                                          }}
                                          inputProps={{
                                            maxLength: 255,
                                          }}
                                          onKeyPress={
                                            commonValidation.validateHtmlElement
                                          }
                                          className="inpClr"
                                          size="small"
                                          fullWidth
                                          variant="outlined"
                                          required
                                          label="What experience, education, skill sets, or training are you able to offer to a mentee?"
                                          {...getFieldProps("offer")}
                                          error={Boolean(
                                            touched.offer && errors.offer
                                          )}
                                          helperText={
                                            touched.offer && errors.offer
                                          }
                                        />
                                      </Grid>
                                    )}
                                    {values.roleId != "2" && (
                                      <Grid item xs={12}>
                                        <TextField
                                          inputProps={{
                                            maxLength: 255,
                                          }}
                                          InputProps={{
                                            autoComplete: "off",
                                          }}
                                          onKeyPress={
                                            commonValidation.validateHtmlElement
                                          }
                                          className="inpClr"
                                          size="small"
                                          fullWidth
                                          variant="outlined"
                                          required
                                          label="What skill sets and areas of life do you believe a mentor can help improve?"
                                          {...getFieldProps("improve")}
                                          error={Boolean(
                                            touched.improve && errors.improve
                                          )}
                                          helperText={
                                            touched.improve && errors.improve
                                          }
                                        />
                                      </Grid>
                                    )}
                                    {values.roleId != "3" && (
                                      <Grid item xs={12}>
                                        <TextField
                                          inputProps={{
                                            maxLength: 255,
                                          }}
                                          InputProps={{
                                            autoComplete: "off",
                                          }}
                                          onKeyPress={
                                            commonValidation.validateHtmlElement
                                          }
                                          className="inpClr"
                                          size="small"
                                          fullWidth
                                          variant="outlined"
                                          required
                                          label="Would you be available to meet with a Mentee virtually at least 60 Minutes per Quarter?"
                                          {...getFieldProps("virtualMeet")}
                                          error={Boolean(
                                            touched.virtualMeet &&
                                              errors.virtualMeet
                                          )}
                                          helperText={
                                            touched.virtualMeet &&
                                            errors.virtualMeet
                                          }
                                        />
                                      </Grid>
                                    )}
                                    {values.roleId != "2" && (
                                      <Grid item xs={12}>
                                        <TextField
                                          inputProps={{
                                            maxLength: 255,
                                          }}
                                          InputProps={{
                                            autoComplete: "off",
                                          }}
                                          onKeyPress={
                                            commonValidation.validateHtmlElement
                                          }
                                          className="inpClr"
                                          size="small"
                                          fullWidth
                                          variant="outlined"
                                          required
                                          label="How much time per semester and over the summer are you able to meet with your mentor?"
                                          {...getFieldProps("semester")}
                                          error={Boolean(
                                            touched.semester && errors.semester
                                          )}
                                          helperText={
                                            touched.semester && errors.semester
                                          }
                                        />
                                      </Grid>
                                    )}
                                    <Grid item xs={12}>
                                      <TextField
                                        inputProps={{
                                          maxLength: 255,
                                        }}
                                        InputProps={{
                                          autoComplete: "off",
                                        }}
                                        onKeyPress={
                                          commonValidation.validateHtmlElement
                                        }
                                        className="inpClr"
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        label={
                                          values.roleId == "3"
                                            ? "What did you consider to be your greatest challenge and how did you overcome it?"
                                            : "Would you be willing to share some of your career or personal challenges and how did you overcome them?"
                                        }
                                        {...getFieldProps("sharePersonal")}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <TextField
                                        InputProps={{
                                          autoComplete: "off",
                                        }}
                                        inputProps={{
                                          maxLength: 255,
                                        }}
                                        onKeyPress={
                                          commonValidation.validateHtmlElement
                                        }
                                        className="inpClr"
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        label="What do you consider to be your strengths and weaknesses?"
                                        {...getFieldProps("strenWeak")}
                                      />
                                    </Grid>

                                    <Grid item xs={12}>
                                      <div className="layoutBorder">
                                        {(values.roleId == "2" ||
                                          values.roleId == "4") && (
                                          <label>
                                            Do you have a story of how a Mentor
                                            helped you in your career and would
                                            you be willing to share that here
                                            and on video for students?{" "}
                                          </label>
                                        )}
                                        {values.roleId == "3" && (
                                          <label>
                                            Do you have a story of how a Mentor
                                            helped you in your career and would
                                            you be willing to share that here?{" "}
                                          </label>
                                        )}
                                        <div>
                                          <div>
                                            <ReactQuill
                                              value={content}
                                              onKeyDown={(e) =>
                                                handleKeyDown(e, "c")
                                              }
                                              onChange={(e) =>
                                                handleChange(e, "c")
                                              }
                                            />
                                          </div>
                                          <br></br>
                                          <div>
                                            <div
                                              {...getRootProps3()}
                                              style={dropzoneStyles}
                                              className="dropContent"
                                            >
                                              <input {...getInputProps3()} />
                                              <UploadFileIcon />
                                              <p> Click here to Upload </p>
                                            </div>
                                            <div className="fiUpload">
                                              <ul>
                                                {files2.map((file, index) => (
                                                  <li key={index}>
                                                    {file.name}
                                                    &nbsp;&nbsp;{" "}
                                                    <DeleteOutlineIcon
                                                      className="del"
                                                      onClick={() =>
                                                        removeFile(
                                                          index,
                                                          "video"
                                                        )
                                                      }
                                                    />
                                                    {/* <button onClick={() => removeFile(index, "video")}>Remove</button> */}
                                                  </li>
                                                ))}
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item xs={12}></Grid>
                                  </Grid>
                                </Stack>
                              )}{" "}
                              {/* looking for end */}
                            </Form>
                          </FormikProvider>

                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              pt: 2,
                              mb: 10,
                            }}
                          >
                            <Button
                              variant="contained"
                              className="cancelBtn"
                              onClick={handleCancel}
                              sx={{ mr: 1 }}
                            >
                              Cancel
                            </Button>
                            <Box sx={{ flex: "1 1 auto" }} />
                            {/* {isStepOptional(activeStep) && (
                              //handleSkip
                              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                Skip
                              </Button>
                            )} */}

                            <Button
                              variant="contained"
                              className="nxtBtn"
                              disabled={activeStep === 0}
                              onClick={handleBack}
                              sx={{ mr: 1 }}
                            >
                              Back
                            </Button>
                            {activeStep !== steps.length - 1 ? (
                              <Button variant="contained" onClick={handleNext}>
                                Next
                              </Button>
                            ) : (
                              ""
                            )}
                            {activeStep === steps.length - 1 ? (
                              <Button
                                variant="contained"
                                onClick={handleSubmit}
                              >
                                Submit
                              </Button>
                            ) : (
                              ""
                            )}
                          </Box>
                        </React.Fragment>
                      )}
                    </Box>

                    {/* stepper end */}
                  </div>
                </Grid>
              </div>
            </Grid>
          </Stack>
        </div>
        <Footer />
      </div>
    </>
  );
}
