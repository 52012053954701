import React from "react";
import feature1 from "../../assets/images/feature_1.png";
import feature2 from "../../assets/images/feature_2.png";
import feature3 from "../../assets/images/feature_3.png";

const HomeFeatures = () => {
  return (
    <div className="container">
      <div className="home-features">
        <ul>
          <li>
            <div className="list-img">
              <img src={feature1} alt="feature img" />
            </div>
            <div className="list-info">
              <h4>Inspiring Ambitions</h4>
              <p>
                "In the fast-paced tech ecosystem, Mango Mentors offers a haven
                of guidance, personal growth, and professional development. Dive
                into a transformative experience that has been meticulously
                crafted for both mentors and mentees alike."
              </p>
            </div>
          </li>
          <li>
            <div className="list-img">
              <img src={feature2} alt="feature img" />
            </div>
            <div className="list-info">
              <h4>Mango Mentors' Ethos</h4>
              <p>
                "Every tech dream is a journey. At Mango Mentors, we ensure
                you're not walking it alone. With every step, there's guidance,
                with every challenge, there's support, and with every
                achievement, there's a community cheering for you."
              </p>
            </div>
          </li>
          <li>
            <div className="list-img">
              <img src={feature3} alt="feature img" />
            </div>
            <div className="list-info">
              <h4>Spotlight Feature</h4>
              <p>
                Spotlight Feature: Every month, shine a light on a mentee's
                journey or a mentor's experiences.
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default HomeFeatures;
