// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-hide {
    display: none;
  }
  
  .loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999 !important;
    background: rgba(0, 0, 0, 0.5)
  }
  
  .loader svg {
    position: absolute;
    left: 44%;
    top: 40%;
    z-index: 1000;
    height: 60px;
    width: 100px;
  }
  .iconfy_icon{
    color: white !important;
  }`, "",{"version":3,"sources":["webpack://./src/style/loader.css"],"names":[],"mappings":"AAAA;IACI,aAAa;EACf;;EAEA;IACE,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,0BAA0B;IAC1B;EACF;;EAEA;IACE,kBAAkB;IAClB,SAAS;IACT,QAAQ;IACR,aAAa;IACb,YAAY;IACZ,YAAY;EACd;EACA;IACE,uBAAuB;EACzB","sourcesContent":[".loader-hide {\n    display: none;\n  }\n  \n  .loader {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    z-index: 999999 !important;\n    background: rgba(0, 0, 0, 0.5)\n  }\n  \n  .loader svg {\n    position: absolute;\n    left: 44%;\n    top: 40%;\n    z-index: 1000;\n    height: 60px;\n    width: 100px;\n  }\n  .iconfy_icon{\n    color: white !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
