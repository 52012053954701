import { Helmet } from "react-helmet-async";
// @mui
import { styled } from "@mui/material/styles";
import {
  Link,
  Container,
  Typography,
  Divider,
  Stack,
  Button,
  Grid,
} from "@mui/material";
// hooks
import useResponsive from "../hooks/useResponsive";
// components

// sections
import { LoginForm } from "../sections/auth/login";
import { Link as RouterLink } from "react-router-dom";
import LoginSignTerms from "./LoginSignTerms";
// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  display: "flex",
  minHeight: "100vh",
  background: "#fff",

  [theme.breakpoints.down(991)]: {
    display: "block",
  },
}));

const StyledSection = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

const StyledContent = styled("div")(({ theme }) => ({
  justifyContent: "space-between",
  maxWidth: 480,
  margin: "auto",
  width: "100%",
  display: "flex",
  flexDirection: "column",

  [theme.breakpoints.up(2000)]: {
    maxWidth: 600,
    width: "100%",
  },
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  return (
    <>
      <Helmet>
        <title> Login | Mentor </title>
      </Helmet>

      {/* <StyledRoot className="login-page-wrap"> */}
      <LoginForm />
        {/* <img src="/assets/icons/Mangomentors_logo_clr_nbg.png" alt="login" /> */}
     
      {/* </StyledRoot> */}
    </>
  );
}
