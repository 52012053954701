import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
  Link,
  Typography,
  Grid,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { SignupService } from "src/services/SignupService";
import { Form, FormikProvider, useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { Icon } from "@iconify/react";
import CachedIcon from "@mui/icons-material/Cached";
import closeFill from "@iconify/icons-eva/close-fill";
import MenuItem from "@mui/material/MenuItem";
import Termscondition from "src/pages/Termscondition";
import Logo from "../../assets/images/logo_mentor.svg";
import FavIcon from "../../assets/images/favicon.svg";
import BannerImg from "../../assets/images/grp-img.png";
import { commonValidation } from "src/utils/commonValidation";

export default function SignupForm() {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [title, setTitle] = useState([]);
  const [occupant, setOccupant] = useState([]);
  const [value, setValue] = useState("");
  const signupSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email address")
      .required("Email address is required"),
    Password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Password should be minimum length of 8 characters, at least one uppercase letter, one numeric value, and one special symbol."
      )
      .max(64, "Password length should not exceed more than 64 characters"),
    CellPhone: Yup.string(),
    other_occupation: Yup.string(),
    OccupationID: Yup.string().required("Occupation is required"),
    Captcha: Yup.string().required("Captcha is required"),
    // TitleID: Yup.string(),
    FirstName: Yup.string()
      .required("First name is required")
      .trim("First name is required"),
    LastName: Yup.string()
      .required("Last name is required")
      .trim("Last name is required"),
    confirmpassword: Yup.string()
      .required("Password is required")
      .oneOf([Yup.ref("Password")], "Passwords does not match"),
  });

  useEffect(() => {
    // getTitleList();
    getOccupantList();
    localStorage.setItem(
      "mangoMentorsAuth",
      JSON.stringify({
        isAuth: false,
        token: "",
        userId: "",
        email: "",
        name: "",
        phone: "",
        lname: "",
        userstatus: "",
      })
    );
    window.scrollTo(0, 0);
  }, []);

  const handleClick = () => {
    // localStorage.setItem("mangoMentorsAuth", JSON.stringify({ isAuth: true }));
    navigate("/login", { replace: true });
  };
  const toaster = (error, type) => {
    enqueueSnackbar(error, {
      variant: type,
      action: (key) => (
        <>
          <Icon
            icon={closeFill}
            size="small"
            style={{ marginBottom: "41px", cursor: "pointer" }}
            onClick={() => closeSnackbar(key)}
          />
        </>
      ),
    });
  };

  //  code for captcha
  const [captchaText, setCaptchaText] = useState("");
  const [userInput, setUserInput] = useState("");
  const canvasRef = useRef(null);

  useEffect(() => {
    renderCaptcha();
  }, []);
  const renderCaptcha = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    initializeCaptcha(ctx);
  };
  const generateRandomChar = (min, max) =>
    String.fromCharCode(Math.floor(Math.random() * (max - min + 1) + min));

  const generateCaptchaText = () => {
    let captcha = "";
    for (let i = 0; i < 3; i++) {
      captcha += generateRandomChar(65, 90);
      captcha += generateRandomChar(97, 122);
      captcha += generateRandomChar(48, 57);
    }
    return captcha
      .split("")
      .sort(() => Math.random() - 0.5)
      .join("");
  };

  const drawCaptchaOnCanvas = (ctx, captcha) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    const textColors = ["rgb(0,0,0)", "rgb(130,130,130)"];
    const letterSpace = 150 / captcha.length;
    for (let i = 0; i < captcha.length; i++) {
      const xInitialSpace = 25;
      ctx.font = "20px Roboto Mono";
      ctx.fillStyle = textColors[Math.floor(Math.random() * 2)];
      ctx.fillText(
        captcha[i],
        xInitialSpace + i * letterSpace,

        // Randomize Y position slightly
        Math.floor(Math.random() * 16 + 25),
        100
      );
    }
  };

  const initializeCaptcha = (ctx) => {
    setUserInput("");
    const newCaptcha = generateCaptchaText();
    setCaptchaText(newCaptcha.slice(0, -4));
    drawCaptchaOnCanvas(ctx, newCaptcha.slice(0, -4));
  };

  const nav = () => {
    navigate("/", { replace: true });
  };

  // const handleCaptchaSubmit = () => {
  //   if (userInput === captchaText) {
  //     alert("Success");
  //   } else {
  //     alert("Enter the captcha");
  //     const canvas = canvasRef.current;
  //     const ctx = canvas.getContext("2d");
  //     initializeCaptcha(ctx);
  //   }
  // };
  const navLogin = () => {
    navigate("/login", { replace: true });
  };
  // const getTitleList = async () => {
  //   await SignupService.getTitleList()
  //     .then(
  //       (res) => {
  //         if (res) {
  //           if (res?.status) {
  //             setTitle(res?.data?.titles)
  //           }
  //         }
  //       },
  //       (error) => {
  //         toaster(JSON.stringify(error?.data?.detail || "Network error"), "error");
  //       }
  //     )
  //     .catch((error) => {
  //       toaster(JSON.stringify(error), "error");
  //     });
  // }
  const getOccupantList = async () => {
    await SignupService.getOccupantList()
      .then(
        (res) => {
          if (res) {
            if (res?.status) {
              setOccupant(res?.data?.occupations);
            }
          }
        },
        (error) => {
          toaster(
            JSON.stringify(error?.data?.detail || "Network error"),
            "error"
          );
        }
      )
      .catch((error) => {
        toaster(JSON.stringify(error), "error");
      });
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      // TitleID: "",
      FirstName: "",
      LastName: "",
      CellPhone: "",
      OccupationID: "",
      email: "",
      Password: "",
      confirmpassword: "",
      Captcha: "",
      other_occupation: "",
    },
    validationSchema: signupSchema,
    onSubmit: async (values, { setSubmitting, setErrors, resetForm }) => {
      if (values.Captcha != captchaText) {
        return toaster(
          "Entered captcha is not matched with generated captcha",
          "error"
        );
      }
      try {
        const data = new FormData();
        // data.append("titleid", values.TitleID);
        data.append("firstname", values.FirstName.toString());
        data.append("lastname", values.LastName.toString());
        data.append("cellphone", values.CellPhone.toString());
        data.append("occupationid", values.OccupationID);
        data.append("email", values.email.toString());
        data.append("password", values.Password.toString());
        data.append("other_occupation", values.other_occupation);
        await SignupService.signupPostData(data)
          .then(
            (res) => {
              if (res) {
                if (res?.status) {
                  navigate("/login", { replace: true });
                  // navigate("/registerSuccess", { replace: true });
                  console.log(res, "resp");
                  toaster(res?.data?.message.toString(), "success");
                } else {
                  toaster("Registration Failed", "error");
                }
              }
            },
            (error) => {
              toaster(
                JSON.stringify(error?.data?.detail || "Network error"),
                "error"
              );
            }
          )
          .catch((error) => {
            toaster(JSON.stringify(error), "error");
          });
      } catch (error) {
        console.log(error, "er");
        setSubmitting(false);
        setErrors(error);
        toaster(JSON.stringify(error), "error");
      }
    },
  });

  const { errors, touched, handleSubmit, getFieldProps, setFieldValue } =
    formik;
  const occupantHandle = (data, key) => {
    console.log(data.target, "data");
    setValue(data.target.value);
    setFieldValue(key, data.target.value);
    setFieldValue("other_occupation", "");
  };

  return (
    <>
      <div className="auth-wrap">
        <div className="containerl con-sign">
          <div className="auth-card sign-up">
            <div className="img-wrap">
              <Grid container spacing={2} className="auth-title-info">
                <Grid item>
                  <img
                    src="/assets/images/mango-slight-smile.png"
                    alt="Mango"
                  />
                </Grid>
                <Grid item>
                  <h1 className="fPopin">
                    Empowering{" "}
                    <span className="spanFW">future technology </span>
                    leaders <span className="spanFW">and</span> executives!
                  </h1>
                </Grid>
              </Grid>

              <div className="grp-img">
                <img src={BannerImg} alt="group" />
              </div>
            </div>
            <div className="form-wrap">
              <img src={Logo} alt="logo" className="logo" onClick={nav} />
              <img src={FavIcon} alt="favicon" className="fav-icon" />
              <h3>Signup</h3>
              <span>and Get Started</span>
              {/*  */}
              <FormikProvider value={formik}>
                <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                  <Stack spacing={2} alignItems="center">
                    <Grid container spacing={1}>
                      {/* <div className="title-drop"> */}
                      {/* <Grid item xs={3}>
                        <TextField
                          fullWidth
                          // required
                          className="inpClr"
                          select
                          label="Title"
                          {...getFieldProps("TitleID")}
                          // error={Boolean(touched.TitleID && errors.TitleID)}
                          // helperText={touched.TitleID && errors.TitleID}
                        >
                          {title.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.title}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid> */}
                      <Grid item xs={6}>
                        <TextField
                          InputProps={{
                            autoComplete: "off",
                          }}
                          inputProps={{
                            maxLength: 40,
                          }}
                          required
                          className="inpClr"
                          size="small"
                          fullWidth
                          variant="outlined"
                          label="First Name"
                          placeholder="Enter First Name"
                          {...getFieldProps("FirstName")}
                          error={Boolean(touched.FirstName && errors.FirstName)}
                          helperText={touched.FirstName && errors.FirstName}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          InputProps={{
                            autoComplete: "off",
                          }}
                          inputProps={{
                            maxLength: 40,
                          }}
                          required
                          className="inpClr"
                          size="small"
                          fullWidth
                          variant="outlined"
                          label="Last Name"
                          placeholder="Enter Last Name"
                          {...getFieldProps("LastName")}
                          error={Boolean(touched.LastName && errors.LastName)}
                          helperText={touched.LastName && errors.LastName}
                        />
                      </Grid>

                      {/* </div> */}
                      {/* <div> */}
                      <Grid item xs={12}>
                        <TextField
                          // type="number"
                          onKeyPress={commonValidation.validateNumber}
                          InputProps={{
                            autoComplete: "off",
                            inputProps: {
                              maxLength: 15,
                            },
                          }}
                          className="inpClr"
                          size="small"
                          fullWidth
                          variant="outlined"
                          label="Cell Phone"
                          placeholder="Enter Cell Phone Number"
                          {...getFieldProps("CellPhone")}
                          // error={Boolean(touched.CellPhone && errors.CellPhone)}
                          // helperText={touched.CellPhone && errors.CellPhone}
                        />
                      </Grid>
                      {/* </div>
                <div> */}
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          select
                          required
                          className="inpClr labSel"
                          label="Occupation"
                          //  {...getFieldProps("OccupationID")}
                          name="OccupationID"
                          value={value}
                          error={Boolean(
                            touched.OccupationID && errors.OccupationID
                          )}
                          helperText={
                            touched.OccupationID && errors.OccupationID
                          }
                          onChange={(e) => occupantHandle(e, "OccupationID")}
                        >
                          {occupant.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.occupation}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      {value == 5 && (
                        <Grid item xs={12}>
                          <TextField
                            InputProps={{
                              autoComplete: "off",
                              startAdornment: (
                                <InputAdornment position="start"></InputAdornment>
                              ),
                            }}
                            className="inpClr"
                            size="small"
                            fullWidth
                            variant="outlined"
                            label="Others"
                            placeholder="Enter other option"
                            {...getFieldProps("other_occupation")}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <TextField
                          InputProps={{
                            autoComplete: "off",
                            startAdornment: (
                              <InputAdornment position="start"></InputAdornment>
                            ),
                          }}
                          className="inpClr"
                          size="small"
                          fullWidth
                          variant="outlined"
                          required
                          label="Email Address"
                          placeholder="Enter your email address"
                          {...getFieldProps("email")}
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          className="inpClr"
                          required
                          fullWidth
                          size="small"
                          placeholder="Enter your password"
                          inputProps={{
                            maxLength: 65,
                          }}
                          {...getFieldProps("Password")}
                          error={Boolean(touched.Password && errors.Password)}
                          helperText={touched.Password && errors.Password}
                          label="Password"
                          type={showPassword ? "text" : "Password"}
                          InputProps={{
                            autoComplete: "off",
                            startAdornment: (
                              <InputAdornment position="start">
                                {" "}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          className="inpClr"
                          required
                          fullWidth
                          size="small"
                          placeholder="Confirm your password"
                          inputProps={{
                            maxLength: 65,
                          }}
                          {...getFieldProps("confirmpassword")}
                          error={Boolean(
                            touched.confirmpassword && errors.confirmpassword
                          )}
                          helperText={
                            touched.confirmpassword && errors.confirmpassword
                          }
                          label="Confirm Password"
                          type={showPassword ? "text" : "Password"}
                          InputProps={{
                            autoComplete: "off",
                            startAdornment: (
                              <InputAdornment position="start">
                                {" "}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} className="shwTop">
                        {" "}
                        <span>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onClick={() => setShowPassword(!showPassword)}
                                />
                              }
                              className="shwPass"
                              label="Show Password"
                            />
                          </FormGroup>
                        </span>{" "}
                      </Grid>
                      <Grid item xs={6} className="shwTop">
                        {" "}
                      </Grid>

                      <Grid item xs={6}>
                        {/* </div> */}
                        <TextField
                          InputProps={{
                            autoComplete: "off",
                          }}
                          className="inpClr"
                          size="small"
                          fullWidth
                          required
                          inputProps={{
                            maxLength: 20,
                          }}
                          variant="outlined"
                          label="Enter Captcha"
                          placeholder="Enter Captcha"
                          {...getFieldProps("Captcha")}
                          error={Boolean(touched.Captcha && errors.Captcha)}
                          helperText={touched.Captcha && errors.Captcha}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <canvas
                          ref={canvasRef}
                          width="200"
                          className="capcha"
                          height="70"
                        ></canvas>
                      </Grid>

                      <Grid item xs={1}>
                        <CachedIcon
                          className="capRefresh"
                          onClick={renderCaptcha}
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                  <Stack alignItems="center">
                    <LoadingButton
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      className="btClr"
                      // onClick={handleSubmit}
                    >
                      SIGNUP
                    </LoadingButton>
                    <Typography
                      variant="subtitle1"
                      align="center"
                      sx={{ px: 5, mt: 3 }}
                      // color="#fff"
                      className="typoAcc"
                    >
                      Already have account?
                    </Typography>
                    <Link
                      variant="subtitle2"
                      underline="hover"
                      className="forgot-link "
                      sx={{ px: 5, mb: 4, cursor: "pointer" }}
                      onClick={navLogin}
                    >
                      Login
                    </Link>
                  </Stack>
                </Form>
              </FormikProvider>
              <Termscondition />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
