// @mui

import React from "react";
// @mui
import {
  Stack,
  IconButton,
  TextField,
  Link,
  Typography,
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Grid,
  FormGroup,
  FormControlLabel,
  InputAdornment,
  FormControl,
  FormLabel,
  Checkbox,
  Autocomplete,
  RadioGroup,
  Radio,
} from "@mui/material";
import Header from "../components/header";
import Footer from "../components/footer";
import { useNavigate } from "react-router-dom";
import register from "../assets/images/mango_register.svg";
import Iconify from "../components/iconify";

// components

// sections
// ----------------------------------------------------------------------

export default function CompleteReg() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/landing", { replace: true });
  };

  return (
    <>
      <div className="maindiv">
        <Header />
        <Grid container className="bgclr">
          <Grid item xs={3} className="marbtn">
            <div className="imgreg-wrap">
              <img src={register} alt="logo" className="logo" />
            </div>
          </Grid>
          <Grid item xs={7} className="marbtn">
            <div className="text-center comReg">
              <Typography variant="h4" className="typResp">
                <Iconify
                  className="icClrSz"
                  icon="teenyicons:tick-circle-solid"
                />
              </Typography>

              <Typography variant="h3" className="typResp">
                Registration completed
              </Typography>
              <br></br>

              <Button
                onClick={() => handleClick()}
                size="medium"
                variant="contained"
                className="btClr"
              >
                Home Page
              </Button>
            </div>
          </Grid>
        </Grid>

        <Footer />
      </div>
    </>
  );
}
