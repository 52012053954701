import * as React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import AccountCircle from "@mui/icons-material/AccountCircle";
import HttpsIcon from "@mui/icons-material/Https";

import {
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
  Link,
  Typography,
  Grid,
} from "@mui/material";
import Iconify from "src/components/iconify/Iconify";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { LoginService } from "../../../services/LoginService";
import { Icon } from "@iconify/react";
import closeFill from "@iconify/icons-eva/close-fill";
import BannerImg from "../../../assets/images/grp-img.png";
import Logo from "../../../assets/images/logo_mentor.svg";
import FavIcon from "../../../assets/images/favicon.svg";
import Termscondition from "src/pages/Termscondition";
import useData from "src/hooks/useData";
function LoginForm() {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const { statusSetted } = useData();

  const [checked, setChecked] = useState(false);
  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter the valid email address")
      .required("Email address is required"),
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Password should be minimum length of 8 characters, at least one uppercase letter, one numeric value, and one special symbol."
      )
      .max(64, "Password length should not exceed more than 64 characters"),
    rememberme: Yup.string(),
  });

  const toaster = (error, type) => {
    enqueueSnackbar(error, {
      variant: type,
      action: (key) => (
        <>
          <Icon
            icon={closeFill}
            size="small"
            style={{ marginBottom: "41px", cursor: "pointer" }}
            onClick={() => closeSnackbar(key)}
          />
        </>
      ),
    });
  };
  useEffect(() => {
    localStorage.setItem("userstatus", false);
    localStorage.setItem(
      "mangoMentorsAuth",
      JSON.stringify({
        isAuth: false,
        token: "",
        userId: "",
        email: "",
        name: "",
        phone: "",
        lname: "",
        userstatus: "",
      })
    );
  }, []);

  const navSignup = () => {
    navigate("/signUp", { replace: true });
  };
  const nav = () => {
    navigate("/", { replace: true });
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
      // rememberme:rememberData.remembermeDate,
    },
    validationSchema: loginSchema,
    onSubmit: async (values, { setSubmitting, setErrors, resetForm }) => {
      try {
        // console.log(values, checked, "value");

        const data = new FormData();
        data.append("email", values.email.toString());
        data.append("password", values.password.toString());
        await LoginService.loginPostData(data)
          .then(
            (res) => {
              //   await LoginService.getUserList().then((res) => {
              if (res) {
                if (res?.status) {
                  localStorage.setItem(
                    "mangoMentorsAuth",
                    JSON.stringify({
                      isAuth: true,
                      token: res?.data?.access_token,
                      userId: res?.data["user id"],
                      email: res?.data?.email,
                      name: res?.data?.firstname,
                      lname: res?.data?.lastname,
                      phone:
                        res?.data?.cellphone == null
                          ? ""
                          : res?.data?.cellphone,
                      userstatus: res?.data?.userstatus == 1 ? true : false,
                      role: res?.data?.role == 1 ? "admin" : "",
                    })
                  );
                  localStorage.setItem(
                    "userstatus",
                    res?.data?.userstatus == 1 ? true : false
                  );

                  statusSetted(res?.data?.userstatus == 1 ? true : false);
                  navigate("/landing", { replace: true });
                  // if (checked === true) {
                  //   localStorage.setItem(
                  //     "remember",
                  //     JSON.stringify({
                  //       rememberme: true,
                  //       email: values.email.toString(),
                  //       password: values?.password.toString(),
                  //     })
                  //   );
                  // }
                  //   navigate("/dashboard", { replace: true });
                  // console.log(res, "resp", CryptoJS.AES.encrypt(
                  //   JSON.stringify(values?.password),
                  //   secretPass
                  // ));

                  toaster(res?.data?.message.toString(), "success");
                } else {
                  toaster("Invalid User", "error");
                }
              }
            },
            (error) => {
              toaster(
                JSON.stringify(error?.data?.detail || "Network error"),
                "error"
              );
            }
          )
          .catch((error) => {
            toaster(JSON.stringify(error), "error");
          });
      } catch (error) {
        setSubmitting(false);
        setErrors(error);
        toaster(JSON.stringify(error), "error");
      }
    },
  });

  const handleChange = (event) => {
    console.log(event.target.checked, "ch");
    setChecked(event.target.checked);
  };
  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <div className="auth-wrap">
      <div className="containerl">
        <div className="auth-card">
          <div className="form-wrap">
            <img src={Logo} alt="logo" className="logo" onClick={nav} />
            <img src={FavIcon} alt="favicon" className="fav-icon" />
            <h3>Log in</h3>
            <span>Welcome back!</span>
            <FormikProvider value={formik}>
              <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Stack spacing={2} alignItems="center">
                  <TextField
                    InputProps={{
                      autoComplete: "off",
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircle />
                        </InputAdornment>
                      ),
                    }}
                    className="inpClr"
                    size="small"
                    fullWidth
                    variant="outlined"
                    label="Email"
                    placeholder="Enter your email address"
                    {...getFieldProps("email")}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                  <TextField
                    className="inpClr"
                    fullWidth
                    size="small"
                    placeholder="Enter your password"
                    inputProps={{
                      maxLength: 65,
                    }}
                    {...getFieldProps("password")}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      autoComplete: "off",
                      startAdornment: (
                        <InputAdornment position="start">
                          {" "}
                          <HttpsIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            <Iconify
                              className="icClr"
                              icon={showPassword ? "mdi:eye" : "mdi:eye-off"}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
                {/* justifyContent="space-between" */}
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  className="remember-check-wrap"
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={handleChange}
                          // {...getFieldProps("rememberme")}
                          // sx={{
                          //   color: "#fff",
                          //   "&.Mui-checked": {
                          //     color: "#fff",
                          //   },
                          // }}
                        />
                      }
                      label="Remember me"
                      className="remember-check"
                    />
                  </FormGroup>

                  <Link
                    variant="subtitle2"
                    underline="hover"
                    className="forgot-link"
                    // onClick={forgotPassword}
                  >
                    Forgot Password?
                  </Link>
                </Stack>
                <Stack alignItems="center">
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    className="btClr"
                    onClick={handleSubmit}
                  >
                    login
                  </LoadingButton>
                  <Typography
                    variant="subtitle1"
                    align="center"
                    sx={{ px: 5, mt: 3 }}
                    // color="#fff"
                    className="typoAcc"
                  >
                    Don't have account?
                  </Typography>
                  <Link
                    variant="subtitle2"
                    underline="hover"
                    className="linkSignup"
                    sx={{ px: 5, mb: 4, cursor: "pointer" }}
                    onClick={navSignup}
                  >
                    Signup
                  </Link>
                </Stack>
              </Form>
            </FormikProvider>
            <Termscondition />
          </div>

          <div className="img-wrap">
            <Grid container spacing={2} className="auth-title-info">
              <Grid item>
                <img src="/assets/images/mango-slight-smile.png" alt="Mango" />
              </Grid>
              <Grid item>
                <h1 className="fPopin">
                  Empowering <span className="spanFW">future technology </span>
                  leaders <span className="spanFW">and</span> executives!
                </h1>
              </Grid>
            </Grid>

            <div className="grp-img">
              <img src={BannerImg} alt="group" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default LoginForm;
