import React from "react";
import calendar from "../../assets/images/calendar.png";
import news from "../../assets/images/news.png";

const Promotions = () => {
  return (
    <div className="promotion-wrap">
      <div className="container">
        <h2 className="title text-center">Contests & Promotions</h2>
        <div className="home-features">
          <ul>
            <li>
              <div className="list-img">
                <img src={news} alt="news img" />
              </div>
              <div className="list-info">
                <h4>Ignite Challenge:</h4>
                <p>
                  A bi-annual tech contest where mentees present innovative
                  solutions to real-world problems. Partnerships with tech
                  companies can provide tools, platforms, and even job
                  opportunities for winners.
                </p>
              </div>
            </li>
            <li>
              <div className="list-img">
                <img src={calendar} alt="calendar img" />
              </div>
              <div className="list-info">
                <h4>Promotional Events:</h4>
                <p>
                  "Tech Vision 2030" - A series of webinars discussing the
                  future of tech and where the industry is headed. Ideal for
                  both mentors and mentees to attend.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Promotions;
