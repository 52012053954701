import React from "react";
import college1 from "../../assets/images/college_1.png";
import college2 from "../../assets/images/college_2.png";
import college3 from "../../assets/images/college_3.png";

const Colleges = () => {
  return (
    <div className="colleges-wrap" id="colleges">
      <div className="container">
        <h2 className="title">For Colleges</h2>
        <ul>
          <li>
            <div className="img-wrap">
              <img src={college1} alt="college img" />
            </div>
            <div className="info-wrap">
              <h4>Preparing Students Beyond Academia</h4>
              <p>
                In collaboration with Mango Mentors, ensure your students aren't
                just academically accomplished but are industry-ready and
                future-forward.
              </p>{" "}
            </div>
          </li>
          <li>
            <div className="img-wrap">
              <img src={college2} alt="college img" />
            </div>
            <div className="info-wrap">
              <h4>Workshops & Curriculum Integration:</h4>
              <p>
                Together, let's create curriculums that are in sync with
                industry demands. Our mentors can lead workshops, offer guest
                lectures, and even co-create courses.
              </p>
            </div>
          </li>
          <li>
            <div className="img-wrap">
              <img src={college3} alt="college img" />
            </div>
            <div className="info-wrap">
              <h4>Showcase Talents:</h4>
              <p>
                Feature innovative projects or research by students under the
                mentorship program, showcasing the institution's commitment to
                practical learning.
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Colleges;
