export const commonValidation = {};
commonValidation.validateNumber = validateNumber;
commonValidation.validateSpace = validateSpace;
commonValidation.validateNumberYear = validateNumberYear;
commonValidation.validateHtmlElement = validateHtmlElement;
commonValidation.validateHtml = validateHtml;
commonValidation.validateLinkdin = validateLinkdin;



function validateNumber(evt) {
    console.log(evt.target.value.length ,"evt")
    var theEvent = evt || window.event;
    // Handle paste
    if (theEvent.type === 'paste') {
        key = theEvent.clipboardData.getData('text/plain');
    } else {
        // Handle key press
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
    }
      var regex =/^[0-9-]{0,14}$/;
    if (!regex.test(key) || evt?.target?.value?.length>14) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
    }
};
function validateNumberYear(evt) {
    console.log(evt.target.value.length ,"evt")
    var theEvent = evt || window.event;
    // Handle paste
    if (theEvent.type === 'paste') {
        key = theEvent.clipboardData.getData('text/plain');
    } else {
        // Handle key press
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
    }
      var regex =/^[0-9-]{0,3}$/;
    if (!regex.test(key) || evt?.target?.value?.length>3) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
    }
};
function validateHtmlElement(evt) {

      var theEvent = evt || window.event;
      // Handle paste
      if (theEvent.type === 'paste') {
          key = theEvent.clipboardData.getData('text/plain');
      } else {
          // Handle key press
          var key = theEvent.keyCode || theEvent.which;
          key = String.fromCharCode(key);
      }
        var regex = /^[a-zA-Z0-9\s!@#$%^&*()_+{}"'\:;,.~s-]*$/;
      if (!regex.test(key) ) {
          theEvent.returnValue = false;
          if (theEvent.preventDefault) theEvent.preventDefault();
      }
};
function validateHtml(evt) {

    var theEvent = evt || window.event;
    // Handle paste
    if (theEvent.type === 'paste') {
        key = theEvent.clipboardData.getData('text/plain');
    } else {
        // Handle key press
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
    }
      var regex = /^[a-zA-Z0-9\s!@#$%^&*()_+{}\:;,.~s-]*$/;
    if (!regex.test(key) ) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
    }
};
function validateLinkdin(evt) {

    var theEvent = evt || window.event;
    // Handle paste
    if (theEvent.type === 'paste') {
        key = theEvent.clipboardData.getData('text/plain');
    } else {
        // Handle key press
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
    }
      var regex = /^[a-zA-Z0-9\s!@#$%^&*()_/+{}\:;,.~s-]*$/;
    if (!regex.test(key) ) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
    }
};
function validateSpace(event) {
    var k = event ? event.which : window.event.keyCode;
    if (k === 32) return false;
}
