import React, { useState, useEffect } from "react";
import user from "../../assets/images/user.png";
import userIcon from "../../assets/images/user_icon.png";
import quotes from "../../assets/images/quotes.png";
import { Carousel } from "react-responsive-carousel";
import { UserService } from "../../services/UserService";

const TestimonialSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [response, setResponse] = useState([]);
  const updateCurrentSlide = (index) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  useEffect(() => {
    reviewInfo();
  }, []);

  const reviewInfo = async () => {
    await UserService.testimonialGetData()
      .then(
        (res) => {
          if (res) {
            if (res?.status) {
              console.log(res, "res test");
              setResponse(res.data);
            }
          }
        },
        (error) => {
          //          toaster(JSON.stringify(error?.data?.detail || "Network error"), "error");
        }
      )
      .catch((error) => {
        //        toaster(JSON.stringify(error), "error");
      });
  };

  const sliderInfo = [
    {
      name: "Luke",
      image: user,
      value:
        "I owe a lot of my success to mango mentors, who has been an amazing coach for me. My Mentor has not only imparted his technical expertise, but also his wisdom and passion. He has helped me enhance my strengths and overcome my weaknesses. Mango mentors has been a great mentor program in the truest sense of the word.",
    },
    {
      name: "john",
      image: userIcon,

      value:
        "Mentoring isn't just about guiding; it's a two-way street of growth. Here, you don't just teach; you learn, evolve, and enrich your professional journey.",
    },
    {
      name: "ben",
      image: userIcon,

      value:
        "I owe a lot of my success to mango mentors, who has been an amazing coach for me. My Mentor has not only imparted his technical expertise, but also his wisdom and passion. He has helped me enhance my strengths and overcome my weaknesses. Mango mentors has been a great mentor program in the truest sense of the word.",
    },
  ];

  return (
    <div className="testimonial-slider">
      <div className="container">
        <h3 className="title">Testimonials</h3>
      </div>

      <div className="test-slide-wrap">
        <div className="container">
          <Carousel
            autoPlay="off"
            selectedItem={currentSlide}
            onChange={updateCurrentSlide}
            showThumbs={false}
            showStatus={false}
            showArrows={false}
            showIndicators={response.length > 1 ? true : false}
          >
            {response.map((item, index) => {
              return (
                <>
                  <div className="img-wrap">
                    <img
                      src={"data:image/png;base64," + item.ImageStream}
                      alt="user img"
                    />
                  </div>
                  <div className="slide-item" key={index}>
                    <img src={quotes} alt="quote img" />
                    <div className="info-wrap">
                      <p>{item.TestimonialText}</p> <h4>{item.AuthorName}</h4>
                    </div>
                  </div>
                </>
              );
            })}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default TestimonialSlider;
