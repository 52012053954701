import React from "react";
import circle from "../../assets/images/check_circle.png";
import employers from "../../assets/images/employers.png";

const Employers = () => {
  return (
    <div className="employers-wrap" id="employers">
      <div className="container">
        <div className="employers-info">
          <h2 className="title">For Employers</h2>
          <h4 className="sub-title">Why Hire from Mango Mentors?</h4>
          <p>
            Mango Mentees aren't just candidates; they're assets. They come
            equipped with a blend of academic knowledge, practical insights, and
            a proactive mindset shaped by their mentors.
          </p>
          <div className="employers-list">
            <h5>Collaborative Initiatives</h5>
            <ul>
              <li>
                <div className="img-wrap">
                  <img src={circle} alt="check img" />
                </div>
                <div className="info-wrap">
                  <h4>Hackathons</h4>
                  <p>
                    Organize hackathons where mentees can solve real-world
                    challenges presented by your company.
                  </p>
                </div>
              </li>
              <li>
                <div className="img-wrap">
                  <img src={circle} alt="check img" />
                </div>
                <div className="info-wrap">
                  <h4>Internship Programs</h4>
                  <p>
                    Curate specialized internship programs for our mentees,
                    ensuring they bring value from day one.
                  </p>
                </div>
              </li>
              <li>
                <div className="img-wrap">
                  <img src={circle} alt="check img" />
                </div>
                <div className="info-wrap">
                  <h4>Recruitment Drives</h4>
                  <p>
                    Access a talent pool that is pre-vetted, trained, and
                    aligned with industry needs.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="employers-img">
          <img src={employers} alt="employers img" />
        </div>
      </div>
    </div>
  );
};

export default Employers;
