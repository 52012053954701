import { get, postFormData } from "./BaseService";
import { apiBaseUrl } from "../config";

export const UserService = { landingGetData, testimonialGetData, getUsersList };

function landingGetData(data) {
  console.log(data, "landing");
  const url = `first_registration_get/`;
  return postFormData(apiBaseUrl + url, data);
}

function testimonialGetData() {
  const url = `get_testimonials/`;
  return get(apiBaseUrl + url);
}

function getUsersList() {
  const url = `get_users_list/`;
  return get(apiBaseUrl + url);
}
